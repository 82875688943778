import { AuthResponseType } from './authResponseTypes';

export const LOGIN_STATE_TYPE = 'login';
export const REGISTER_STATE_TYPE = 'register';

export type AuthStateType = typeof LOGIN_STATE_TYPE | typeof REGISTER_STATE_TYPE;

export type AuthModalPayloadType = {
  selectedAuthStateType?: AuthStateType
};

export type AuthType = AuthResponseType;
