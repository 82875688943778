import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';

type PropsType = {
  title: string;
  Controls: ReactNode;
  isShowSymbols: boolean;
  isWrapSymbols: boolean;
  Symbols: ReactNode;
  toggleShowSymbols: (isShow: boolean) => void;
  toggleWrapSymbols: (isWrap: boolean) => void;
};

const FontComponent: FC<PropsType> = ({
  title,
  Controls,
  isShowSymbols,
  isWrapSymbols,
  Symbols,
  toggleShowSymbols,
  toggleWrapSymbols,
}: PropsType) => {
  const [isShow, setIsShow] = useState(isShowSymbols);
  const [isWrap, setIsWrap] = useState(isWrapSymbols);

  const toggleShow = () => setIsShow(((prevState) => {
    const updatedValues = !prevState;

    toggleShowSymbols(updatedValues);

    return updatedValues;
  }));

  const toggleWrap = () => setIsWrap(((prevState) => {
    const updatedValues = !prevState;

    toggleWrapSymbols(updatedValues);

    return updatedValues;
  }));

  return (
        <div className="border-solid border-b mb-16 pb-16 last:border-none">
            <div className="flex items-center justify-between mb-8 md:justify-start">
                <div className="text-20 mr-20">{title}</div>
                {Controls}
            </div>
            <div className="flex items-center">
                <span className="flex cursor-pointer mr-16" onClick={toggleShow}>
                    {isShow ? '▼' : '▲'} Символы
                </span>
                <span className="flex cursor-pointer underline" onClick={toggleWrap}>
                    {isWrap ? 'Развернуть список' : 'Свернуть список'}
                </span>
            </div>
            {isShow && (
                <div className={classNames('flex flex-wrap overflow-y-auto -mx-16', { 'flex-nowrap': isWrap })}>
                    {Symbols}
                </div>
            )}
        </div>
  );
};

export default FontComponent;
