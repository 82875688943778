import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStats } from './useStats';
import { AppStateTypes } from '../../store/appStateTypes';

export const StatsContainer: FC = () => {
  const usersCounter = useSelector((state: AppStateTypes) => state.stats.usersCounter);
  const fontsCounter = useSelector((state: AppStateTypes) => state.stats.fontsCounter);

  const { loadStatsData } = useStats();

  useEffect(() => {
    loadStatsData();
  }, []);

  return (
      <div className="text-12 mb-16">
          <div>Зарегистрировано<br />пользователей: <span className="font-semibold">{usersCounter.toLocaleString('ru')}</span></div>
          -
          <div>Сгенерировано<br />шрифтов: <span className="font-semibold">{fontsCounter.toLocaleString('ru')}</span></div>
      </div>
  );
};
