import { russianAlphabet } from '@pisaka/core/alphabets/russianAlphabet';
import { arabicNumerals } from '@pisaka/core/alphabets/arabicNumerals';
import { specialSymbols } from '@pisaka/core/alphabets/specialSymbols';
import { englishAlphabet } from '@pisaka/core/alphabets/englishAlphabet';
import { ukrainianAlphabet } from '@pisaka/core/alphabets/ukrainianAlphabet';
import { belorussianAlphabet } from '@pisaka/core/alphabets/belorussianAlphabet';
import { bashkirAlphabet } from '@pisaka/core/alphabets/bashkirAlphabet';
import { tatarAlphabet } from '@pisaka/core/alphabets/tatarAlphabet';
import { kazakhAlphabet } from '@pisaka/core/alphabets/kazakhAlphabet';
import { armenianAlphabet } from '@pisaka/core/alphabets/armenianAlphabet';
import { georgianAlphabet } from '@pisaka/core/alphabets/georgianAlphabet';
import { turkishAlphabet } from '@pisaka/core/alphabets/turkishAlphabet';

type AlphabetsType = {
  [alphabetCountryCode: string]: {
    [alphabetCode: string]: number
  }
};

export const alphabets: AlphabetsType = {
  ru: { ...russianAlphabet, ...arabicNumerals, ...specialSymbols },
  en: { ...englishAlphabet, ...arabicNumerals, ...specialSymbols },
  ua: { ...ukrainianAlphabet, ...arabicNumerals, ...specialSymbols },
  by: { ...belorussianAlphabet, ...arabicNumerals, ...specialSymbols },
  'ru-ba': { ...bashkirAlphabet, ...arabicNumerals, ...specialSymbols },
  'ru-ta': { ...tatarAlphabet, ...arabicNumerals, ...specialSymbols },
  kz: { ...kazakhAlphabet, ...arabicNumerals, ...specialSymbols },
  am: { ...armenianAlphabet, ...arabicNumerals, ...specialSymbols },
  ge: { ...georgianAlphabet, ...arabicNumerals, ...specialSymbols },
  tr: { ...turkishAlphabet, ...arabicNumerals, ...specialSymbols },
};
