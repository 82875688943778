import React, {
  FC, useEffect, useRef, useState
} from 'react';
import Paper from 'paper';
import { SymbolComponent } from '../symbol/SymbolComponent';
import ButtonComponent from '../button/ButtonComponent';

type DrawType = {
  setIsNotEmpty: () => void;
};

const onDraw = ({ setIsNotEmpty }: DrawType) => {
  let myPath: paper.Path;

  Paper.view.onMouseDown = (event: MouseEvent) => {
    if (myPath) {
      myPath.selected = false;
    }

    myPath = new Paper.Path({
      // @ts-ignore
      segments: [event.point],
      strokeColor: 'black',
      strokeWidth: 5,
    });
  };

  // @ts-ignore
  Paper.view.onMouseDrag = (event) => {
    myPath.add(event.point);
    setIsNotEmpty();
  };

  Paper.view.onMouseUp = () => {
    const copyPath = myPath.clone();

    myPath.join(copyPath);
  };
};

const Canvas: FC<DrawType> = ({ setIsNotEmpty }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      Paper.setup(canvas);

      onDraw({ setIsNotEmpty });
    }
  }, [canvasRef]);

  return <canvas style={{
    width: '150px', height: '200px', background: 'transparent', zIndex: 1
  }} ref={canvasRef} id="canvas" />;
};

type PropsType = {
  onSaveSvg: (svgViewString: string, svgViewContent: string) => void;
  symbol: string;
  svgString?: string;
  isLoadingSaveSymbol: boolean;
};

const DrawCardComponent: FC<PropsType> = ({
  onSaveSvg, symbol, svgString, isLoadingSaveSymbol
}: PropsType) => {
  const [isEmpty, setIsEmpty] = useState(!svgString);

  const setIsNotEmpty = () => setIsEmpty(false);

  const onExportSvg = () => {
    setTimeout(() => {
      const svgObjectView = Paper.project.exportSVG({ bounds: 'view' });
      const svgObjectContent = Paper.project.exportSVG({ bounds: 'content' });

      if (typeof svgObjectContent === 'object' && svgObjectContent && typeof svgObjectView === 'object' && svgObjectView) {
        const svgView = svgObjectView.outerHTML;

        const getContentSvg = () => {
          const domParser = new DOMParser();
          const doc = domParser.parseFromString(svgView, 'image/svg+xml');

          const nodeSvg = doc.querySelector('svg');

          nodeSvg && document.body.appendChild(nodeSvg);

          const {
            // @ts-ignore
            x, y, width, height
          } = nodeSvg && nodeSvg.getBBox();

          nodeSvg && nodeSvg.setAttribute('viewBox', `${x - 2.5},${y - 2.5},${width + 5},${height + 5}`);
          nodeSvg && nodeSvg.setAttribute('width', `${width + 5}`);
          nodeSvg && nodeSvg.setAttribute('height', `${height + 5}`);

          const svgStringContent = nodeSvg && nodeSvg.outerHTML;

          nodeSvg && nodeSvg.remove();

          return svgStringContent;
        };

        const svgContent = getContentSvg() || svgObjectContent.outerHTML;

        onSaveSvg(svgView, svgContent);
      }
    });
  };

  const onClearSvg = () => {
    setTimeout(() => {
      setIsEmpty(true);

      Paper.project.clear();
    });
  };

  useEffect(() => {
    onClearSvg();

    setTimeout(() => {
      if (svgString) {
        setIsEmpty(false);

        Paper.project.importSVG(svgString);
      }
    });
  }, [svgString, symbol]);

  return (
        <div className="flex flex-col items-center">
            <SymbolComponent symbol={symbol}>
                <Canvas setIsNotEmpty={setIsNotEmpty} />
            </SymbolComponent>
            <div className="flex items-center">
                <ButtonComponent text="Очистить" onClick={onClearSvg} className="mr-8" isDisabled={isEmpty} />
                <ButtonComponent text="Сохранить" onClick={onExportSvg} isDisabled={isEmpty} isLoading={isLoadingSaveSymbol} />
            </div>
        </div>
  );
};

export default DrawCardComponent;
