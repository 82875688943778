import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './sidebarComponent.module.css';
import {
  basePageUrl, faqPageUrl, fontCreatorPageUrl, offerPageUrl, policyPageUrl
} from '../../../helpers/urls';

type PropsType = {
  toggleSidebar: () => void;
  AuthContent?: ReactNode;
  StatsContent?: ReactNode;
};

export const SidebarComponent: FC<PropsType> = ({ toggleSidebar, AuthContent, StatsContent }) => (
    <div className={classNames('fixed flex flex-col justify-between w-250 top-0 left-0 bottom-0 bg-white border-r border-gray z-10 p-16', styles.container)}>
        <div className="flex items-center absolute right-6 top-6 h-32 w-32 cursor-pointer" onClick={toggleSidebar}>
            <div className="absolute h-3 w-full bg-black rotate-45" />
            <div className="absolute h-3 w-full bg-black -rotate-45" />
        </div>
        <div className="mt-32">
            <div className="flex items-center justify-between mb-16">
                {AuthContent}
            </div>
            <div className="-mx-16">
                <Link to={basePageUrl} className="block py-10 px-16 border-b border-gray">Генератор конспектов</Link>
                <Link to={fontCreatorPageUrl} className="block px-16 py-10 border-b border-gray">
                    Генератор шрифтов
                    <sup className="font-10 text-primary">new</sup>
                </Link>
                <Link to={offerPageUrl} className="block px-16 py-10 border-b border-gray">
                    Оферта
                </Link>
                <Link to={faqPageUrl} className="block px-16 py-10">
                    FAQ
                </Link>
                {/* <Link to={policyPageUrl} className="block px-16 py-10"> */}
                {/*    Политика конфиденциальности */}
                {/* </Link> */}
            </div>
        </div>
        <div className="mt-32">
            {StatsContent}
            Мы в социальных сетях:
            <a href="https://vk.com/pisaka.online" target="_blank" className="flex">
                <div className={styles.vkLogo} />
            </a>
        </div>
    </div>
);
