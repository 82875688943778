import React, { FC, MouseEvent } from 'react';
import { useModal } from '../../../hooks/useModal';
import {
  MODAL_NAME_AUTH,
} from '../../../constants/modalNamesConstants';
import FontCreatorNotAuthControlPanelContainer from './FontCreatorNotAuthControlPanelContainer';
import { REGISTER_STATE_TYPE } from '../../auth/authTypes';

const FontCreatorNotAuthContainer: FC = () => {
  const { onOpenModal } = useModal(MODAL_NAME_AUTH);

  const onOpenRegisterModal = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    onOpenModal({ selectedAuthStateType: REGISTER_STATE_TYPE });
  };

  const onOpenAuthModal = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    onOpenModal();
  };

  return (
        <>
            <FontCreatorNotAuthControlPanelContainer />
            <div className="flex flex-col items-center justify-center h-emptyHeight">
                Что бы создать свой шрифт
                <div>
                    <a href="#" onClick={onOpenRegisterModal} className="text-primary">зарегистрируйтесь</a>{' '}или{' '}
                    <a href="#" onClick={onOpenAuthModal} className="text-primary">авторизуйтесь</a>
                </div>
            </div>
        </>
  );
};

export default FontCreatorNotAuthContainer;
