import { ImageSettingsTypes, ImageTypeEnum } from '../imageSettingsTypes';
import { ImageSettingsActionType } from './actions';
import { ImageSettingsActionListEnum } from './actionsTypes';
import { getItem } from '../../../helpers/localStorage';
import { LOCAL_STORAGE_IMAGE_SETTINGS } from '../../../constants/localStorageConstants';

const storageImageSettings = getItem<ImageSettingsTypes>(LOCAL_STORAGE_IMAGE_SETTINGS);

export const defaultImageSettings = {
  imageType: ImageTypeEnum.CAGE
};

export const initialStateImageSettings = {
  imageType: storageImageSettings?.imageType || defaultImageSettings.imageType,
};

const imageSettings = (state: ImageSettingsTypes = initialStateImageSettings, action: ImageSettingsActionType) => {
  if (action.type === ImageSettingsActionListEnum.CHANGE_IMAGE_TYPE) {
    return {
      ...state,
      imageType: action.imageType,
    };
  }

  if (action.type === ImageSettingsActionListEnum.RESET_IMAGE_SETTINGS) {
    return {
      ...state,
      ...action.imageSettings,
    };
  }

  return state;
};

export default imageSettings;
