import React, {
  ChangeEvent, FC, useEffect, useState
} from 'react';
import styles from './selectComponent.module.css';

type PropsType = {
  name?: string;
  defaultValue: string;
  options: {
    text: string;
    value: string;
  }[];
  onChange: (selectedValue: string) => void;
};

const SelectComponent: FC<PropsType> = ({
  name = 'select',
  defaultValue,
  options,
  onChange = () => {},
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue(e.currentTarget.value);
    onChange(e.currentTarget.value);
  };

  return (
        <select className={styles.select} name={name} onChange={onChangeSelect} value={value}>
            {options.map((option) => <option key={option.value} value={option.value}>{option.text}</option>)}
        </select>
  );
};

export default SelectComponent;
