import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { socketEventGenerateFont, socketEventRegisterUser } from '@pisaka/core/socketEventNames/socketEventNames';
import { socket } from '../helpers/socket';
import { incrementStatsFontsAction, incrementStatsUsersAction } from '../modules/stats/state/actions';

export const useStatsSocket = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on(socketEventGenerateFont, () => {
      dispatch(incrementStatsFontsAction());
    });

    socket.on(socketEventRegisterUser, () => {
      dispatch(incrementStatsUsersAction());
    });
  }, []);
};
