import { Action } from 'redux';
import { FontCreatorActionListEnum } from './actionsTypes';
import { FontCreatorType, SymbolType } from '../fontCreatorTypes';

interface SetFontsTypeActionType extends Action {
  type: FontCreatorActionListEnum.SET_FONTS;
  fonts: FontCreatorType[]
}

interface CreateFontTypeActionType extends Action {
  type: FontCreatorActionListEnum.CREATE_FONT;
  fontId: number;
  fontName: string;
  alphabetCountryCode: string;
  symbols: SymbolType[];
  isShowSymbols: boolean;
  isWrapSymbols: boolean;
  canGenerateFont: boolean;
  canPaymentFont: boolean;
  canDownloadFont: boolean;
}

interface UpdateFontTypeActionType extends Action {
  type: FontCreatorActionListEnum.UPDATE_FONT;
  fontId: number;
  fontName: string;
  alphabetCountryCode: string;
  symbols: SymbolType[];
  isShowSymbols: boolean;
  isWrapSymbols: boolean;
  canGenerateFont: boolean;
  canPaymentFont: boolean;
  canDownloadFont: boolean;
}

interface SaveSymbolTypeActionType extends Action {
  type: FontCreatorActionListEnum.SAVE_SYMBOL;
  fontId: number;
  symbolId: number;
  symbolName: string;
  symbolCode: string;
  symbolSVGView?: string;
  symbolSVGContent?: string;
}

export type FontCreatorActionType = SetFontsTypeActionType | CreateFontTypeActionType | UpdateFontTypeActionType | SaveSymbolTypeActionType;

export const setFontsTypeAction = (fonts: FontCreatorType[]): SetFontsTypeActionType => ({
  type: FontCreatorActionListEnum.SET_FONTS,
  fonts
});

export const createFontTypeAction = (fontData: FontCreatorType): CreateFontTypeActionType => ({
  type: FontCreatorActionListEnum.CREATE_FONT,
  ...fontData,
});

export const updateFontTypeAction = (updatedFontData: FontCreatorType): UpdateFontTypeActionType => ({
  type: FontCreatorActionListEnum.UPDATE_FONT,
  ...updatedFontData,
});

export const saveSymbolTypeAction = ({
  fontId,
  symbolId,
  symbolName,
  symbolCode,
  symbolSVGView,
  symbolSVGContent
}: Pick<CreateFontTypeActionType, 'fontId'> & SymbolType): SaveSymbolTypeActionType => ({
  type: FontCreatorActionListEnum.SAVE_SYMBOL,
  fontId,
  symbolId,
  symbolName,
  symbolCode,
  symbolSVGView,
  symbolSVGContent
});
