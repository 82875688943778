import { Action } from 'redux';
import { AuthActionListEnum } from './actionsTypes';
import { AuthType } from '../authTypes';

interface LoginTypeActionType extends Action, AuthType {
  type: AuthActionListEnum.LOGIN;
}

interface LogoutTypeActionType extends Action {
  type: AuthActionListEnum.LOGOUT;
}

export type AuthActionType = LoginTypeActionType | LogoutTypeActionType;

export const loginTypeAction = (loginProps: AuthType): LoginTypeActionType => ({
  type: AuthActionListEnum.LOGIN,
  ...loginProps,
});

export const logoutTypeAction = (): LogoutTypeActionType => ({
  type: AuthActionListEnum.LOGOUT,
});
