import { ModalActionType } from './actions';
import { ModalActionListEnum } from './actionsTypes';
import { ModalType } from '../modalTypes';

const modal = (state: ModalType = {}, action: ModalActionType) => {
  if (action.type === ModalActionListEnum.OPEN) {
    return {
      ...state,
      [action.modalName]: {
        isOpen: true,
        payload: action.payload,
      },
    };
  }

  if (action.type === ModalActionListEnum.CLOSE) {
    return {
      ...state,
      [action.modalName]: {
        isOpen: false
      },
    };
  }

  return state;
};

export default modal;
