import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { loginUrl, registerUrl } from '@pisaka/core/endpointUrls/endpointUrls';
import { axiosInstance } from '../../helpers/axiosInstance';
import { AuthResponseType } from './authResponseTypes';
import { loginTypeAction } from './state/actions';
import { AppStateTypes } from '../../store/appStateTypes';
import { AuthType } from './authTypes';
import { setCookie } from '../../helpers/cookie';
import { COOKIE_NAME_AUTH_TOKEN } from '../../constants/cookieNamesConstants';

const EMAIL_REGEXP = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const MIN_PASSWORD_LENGTH = 6;
const MAX_PASSWORD_LENGTH = 20;

export const useAuth = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | undefined>();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | undefined>();

  const isValidEmail = !!email.length && !emailError;
  const isValidPassword = !!password.length && !passwordError;

  const onChangeEmail = (value: string) => {
    setEmail(value.toLowerCase());

    if (!value.match(EMAIL_REGEXP)) {
      setEmailError('Введите валидный email!');
    } else {
      setEmailError(undefined);
    }
  };

  const onChangePassword = (value: string) => {
    setPassword(value);

    if (value.length < MIN_PASSWORD_LENGTH || value.length > MAX_PASSWORD_LENGTH) {
      setPasswordError('Пароль должен быть длиной от 6 до 20 символов!');
    } else {
      setPasswordError(undefined);
    }
  };

  const onChangeName = (value: string) => {
    setName(value);
  };

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  return {
    isAuth: !!token,
    email,
    emailError,
    isValidEmail,
    password,
    passwordError,
    isValidPassword,
    name,
    onChangeEmail,
    onChangePassword,
    onChangeName,
  };
};

const setCookieAuth = (authProps: AuthType) => {
  setCookie(COOKIE_NAME_AUTH_TOKEN, JSON.stringify(authProps));
};

export const useLogin = () => {
  const dispatch = useDispatch();

  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [loginError, setLoginError] = useState<string>();

  const login = async (email: string, password: string) => {
    try {
      setIsLoadingLogin(true);

      const { data: { user, token } } = await axiosInstance().post<AuthResponseType>(loginUrl, {
        email,
        password,
      });

      if (user && token) {
        dispatch(loginTypeAction(({ user, token })));
        setCookieAuth({ user, token });
      }
    } catch (error) {
      if (error instanceof Error) {
        // @ts-ignore
        if (error?.response?.data?.error?.message) {
          // @ts-ignore
          throw new Error(error?.response?.data?.error?.message);
        }

        throw new Error(error.message);
      }
    } finally {
      setIsLoadingLogin(false);
    }
  };

  return {
    isLoadingLogin,
    login,
    loginError,
    setLoginError,
  };
};

export const useRegister = () => {
  const dispatch = useDispatch();

  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [registerError, setRegisterError] = useState<string>();

  const register = async (name: string, email: string, password: string) => {
    try {
      setIsLoadingRegister(true);

      const { data: { user, token } } = await axiosInstance().post<AuthResponseType>(registerUrl, {
        name,
        email,
        password,
      });

      if (user && token) {
        dispatch(loginTypeAction(({ user, token })));
        setCookieAuth({ user, token });
      }
    } catch (error) {
      if (error instanceof Error) {
        // @ts-ignore
        if (error?.response?.data?.error?.message) {
          // @ts-ignore
          throw new Error(error?.response?.data?.error?.message);
        }

        throw new Error(error.message);
      }
    } finally {
      setIsLoadingRegister(false);
    }
  };

  return {
    isLoadingRegister,
    register,
    registerError,
    setRegisterError,
  };
};
