export const kazakhAlphabet = {
  'U+0410': 1040, // A
  'U+0430': 1072, // a
  'U+04D8': 1240, // Ә
  'U+04D9': 1241, // ә
  'U+0411': 1041, // Б
  'U+0431': 1073, // б
  'U+0412': 1042, // В
  'U+0432': 1074, // в
  'U+0413': 1043, // Г
  'U+0433': 1075, // г
  'U+0492': 1170, // Ғ
  'U+0493': 1171, // ғ
  'U+0414': 1044, // Д
  'U+0434': 1076, // д
  'U+0415': 1045, // Е
  'U+0435': 1077, // е
  'U+0401': 1025, // Ё
  'U+0451': 1105, // ё
  'U+0416': 1046, // Ж
  'U+0436': 1078, // ж
  'U+0417': 1047, // З
  'U+0437': 1079, // з
  'U+0418': 1048, // И
  'U+0438': 1080, // и
  'U+0419': 1049, // Й
  'U+0439': 1081, // й
  'U+041A': 1050, // К
  'U+043A': 1082, // к
  'U+04A0': 1184, // Ҡ
  'U+04A1': 1185, // ҡ
  'U+041B': 1051, // Л
  'U+043B': 1083, // л
  'U+041C': 1052, // М
  'U+043C': 1084, // м
  'U+041D': 1053, // Н
  'U+043D': 1085, // н
  'U+04A2': 1186, // Ң
  'U+04A3': 1187, // ң
  'U+041E': 1054, // О
  'U+043E': 1086, // о
  'U+04E8': 1256, // Ө
  'U+04E9': 1257, // ө
  'U+041F': 1055, // П
  'U+043F': 1087, // п
  'U+0420': 1056, // Р
  'U+0440': 1088, // р
  'U+0421': 1057, // С
  'U+0441': 1089, // с
  'U+0422': 1058, // Т
  'U+0442': 1090, // т
  'U+0423': 1059, // У
  'U+0443': 1091, // у
  'U+04B0': 1200, // Ұ
  'U+04B1': 1201, // ұ
  'U+04AE': 1198, // Ү
  'U+04AF': 1199, // ү
  'U+0424': 1060, // Ф
  'U+0444': 1092, // ф
  'U+0425': 1061, // Х
  'U+0445': 1093, // х
  'U+04BA': 1210, // Һ
  'U+04BB': 1211, // һ
  'U+0426': 1062, // Ц
  'U+0446': 1094, // ц
  'U+0427': 1063, // Ч
  'U+0447': 1095, // ч
  'U+0428': 1064, // Ш
  'U+0448': 1096, // ш
  'U+0429': 1065, // Щ
  'U+0449': 1097, // щ
  'U+042A': 1066, // Ъ
  'U+044A': 1098, // ъ
  'U+042B': 1067, // Ы
  'U+044B': 1099, // ы
  'U+0406': 1030, // І
  'U+0456': 1110, // і
  'U+042C': 1068, // Ь
  'U+044C': 1100, // ь
  'U+042D': 1069, // Э
  'U+044D': 1101, // э
  'U+042E': 1070, // Ю
  'U+044E': 1102, // ю
  'U+042F': 1071, // Я
  'U+044F': 1103, // я
};
