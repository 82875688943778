import {
  FontTypeEnum, IndentRedLineEnum, TextColorEnum, TextSettingsTypes
} from '../textSettingsTypes';
import { TextSettingsActionType } from './actions';
import { TextSettingsActionListEnum } from './actionsTypes';
import { getItem } from '../../../helpers/localStorage';
import { LOCAL_STORAGE_TEXT_SETTINGS } from '../../../constants/localStorageConstants';

const storageTextSettings = getItem<TextSettingsTypes>(LOCAL_STORAGE_TEXT_SETTINGS);

export const defaultTextSettings = {
  fontType: FontTypeEnum.ABRAM,
  textColor: TextColorEnum.BLUE,
  fontSize: 31,
  lineHeight: 35,
  indentRight: 0,
  indentLeft: 8,
  indentTop: 5,
  indentRedLine: IndentRedLineEnum.CAGE,
  indentLetter: 0,
  indentWord: 0,
  isRandomFontSize: true,
  isRandomRotateLetter: true,
  isRandomLineLeftIndent: true,
};

const initialState = {
  fontType: storageTextSettings?.fontType || defaultTextSettings.fontType,
  textColor: storageTextSettings?.textColor || defaultTextSettings.textColor,
  fontSize: storageTextSettings ? storageTextSettings?.fontSize : defaultTextSettings.fontSize,
  lineHeight: storageTextSettings ? storageTextSettings?.lineHeight : defaultTextSettings.lineHeight,
  indentRight: storageTextSettings ? storageTextSettings?.indentRight : defaultTextSettings.indentRight,
  indentLeft: storageTextSettings ? storageTextSettings?.indentLeft : defaultTextSettings.indentLeft,
  indentTop: storageTextSettings ? storageTextSettings?.indentTop : defaultTextSettings.indentTop,
  indentRedLine: storageTextSettings ? storageTextSettings?.indentRedLine : defaultTextSettings.indentRedLine,
  indentLetter: storageTextSettings ? storageTextSettings?.indentLetter : defaultTextSettings.indentLetter,
  indentWord: storageTextSettings ? storageTextSettings?.indentWord : defaultTextSettings.indentWord,
  isRandomFontSize: storageTextSettings ? storageTextSettings.isRandomFontSize : defaultTextSettings.isRandomFontSize,
  isRandomRotateLetter: storageTextSettings ? storageTextSettings.isRandomRotateLetter : defaultTextSettings.isRandomRotateLetter,
  isRandomLineLeftIndent: storageTextSettings ? storageTextSettings.isRandomLineLeftIndent : defaultTextSettings.isRandomLineLeftIndent,
};

const textSettings = (state: TextSettingsTypes = initialState, action: TextSettingsActionType) => {
  if (action.type === TextSettingsActionListEnum.CHANGE_FONT_TYPE) {
    return {
      ...state,
      fontType: action.fontType,
    };
  }

  if (action.type === TextSettingsActionListEnum.CHANGE_FONT_SIZE) {
    return {
      ...state,
      fontSize: action.fontSize,
    };
  }

  if (action.type === TextSettingsActionListEnum.CHANGE_LINE_HEIGHT) {
    return {
      ...state,
      lineHeight: action.lineHeight,
    };
  }

  if (action.type === TextSettingsActionListEnum.CHANGE_INDENT_RIGHT) {
    return {
      ...state,
      indentRight: action.indentRight,
    };
  }

  if (action.type === TextSettingsActionListEnum.CHANGE_INDENT_LEFT) {
    return {
      ...state,
      indentLeft: action.indentLeft,
    };
  }

  if (action.type === TextSettingsActionListEnum.CHANGE_INDENT_TOP) {
    return {
      ...state,
      indentTop: action.indentTop,
    };
  }

  if (action.type === TextSettingsActionListEnum.CHANGE_TEXT_COLOR) {
    return {
      ...state,
      textColor: action.textColor,
    };
  }

  if (action.type === TextSettingsActionListEnum.CHANGE_INDENT_RED_LINE) {
    return {
      ...state,
      indentRedLine: action.indentRedLine,
    };
  }

  if (action.type === TextSettingsActionListEnum.CHANGE_INDENT_LETTER) {
    return {
      ...state,
      indentLetter: action.indentLetter,
    };
  }

  if (action.type === TextSettingsActionListEnum.CHANGE_INDENT_WORD) {
    return {
      ...state,
      indentWord: action.indentWord,
    };
  }

  if (action.type === TextSettingsActionListEnum.TOGGLE_RANDOM_FONT_SIZE) {
    return {
      ...state,
      isRandomFontSize: action.isRandomFontSize,
    };
  }

  if (action.type === TextSettingsActionListEnum.TOGGLE_RANDOM_ROTATE_LETTER) {
    return {
      ...state,
      isRandomRotateLetter: action.isRandomRotateLetter,
    };
  }

  if (action.type === TextSettingsActionListEnum.TOGGLE_RANDOM_LINE_LEFT_INDENT) {
    return {
      ...state,
      isRandomLineLeftIndent: action.isRandomLineLeftIndent,
    };
  }

  if (action.type === TextSettingsActionListEnum.RESET_TEXT_SETTINGS) {
    return {
      ...state,
      ...action.textSettings,
    };
  }

  return state;
};

export default textSettings;
