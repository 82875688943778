import React, { FC } from 'react';
import DrawCardComponent from '../../../../core/components/drawCard/DrawCardComponent';
import ModalComponent from '../../../../core/components/modal/ModalComponent';
import { useModal } from '../../../../hooks/useModal';
import { MODAL_NAME_CREATE_SYMBOL } from '../../../../constants/modalNamesConstants';
import { useSaveSymbol, useSymbol } from './useSymbol';
import NewDrawCardComponent from '../../../../core/components/drawCard/NewDrawCardComponent';

const CreateSymbolModalContainer: FC = () => {
  const { onCloseModal, payload } = useModal(MODAL_NAME_CREATE_SYMBOL);

  const {
    activeSymbol, prevActiveSymbol, nextActiveSymbol, onPrevSymbol, onNextSymbol
  } = useSymbol(payload);

  const { isLoadingSaveSymbol, saveSymbol } = useSaveSymbol();

  const onSaveSvg = async (svgView: string, svgContent: string) => {
    if (activeSymbol) {
      await saveSymbol(activeSymbol.symbolId, svgView, svgContent);

      nextActiveSymbol && onNextSymbol();
    }
  };

  const isEditSymbol = !!activeSymbol?.symbolSVGView;

  const modalTitle = isEditSymbol ? `Редактировать символ "${activeSymbol?.symbolName}"` : `Создать символ "${activeSymbol?.symbolName}"`;

  const { search } = window.location;

  const isShowNewCard = search.includes('isNewCard');

  return (
        <ModalComponent title={modalTitle} onClose={onCloseModal}>
            <div className="text-14 text-primary">При написании символа, старайтесь не выходить за пределы красных линий!</div>
            <div className="text-14">Красные линии позволяют контролировать одинаковую высоту строчных и прописных символов.</div>
            <div className="flex justify-center items-center relative">
                {prevActiveSymbol && (
                    <div className="absolute left-0 top-0 bottom-0 flex items-center cursor-pointer text-20 p-8 mb-20" onClick={onPrevSymbol}>
                        <div className="-rotate-180 mr-4">►</div>
                        {prevActiveSymbol.symbolName}
                    </div>
                )}
                {activeSymbol && (
                  isShowNewCard ? (
                        <NewDrawCardComponent
                            symbol={activeSymbol.symbolName}
                            onSaveSvg={onSaveSvg}
                            svgString={activeSymbol?.symbolSVGView}
                            isLoadingSaveSymbol={isLoadingSaveSymbol}
                        />
                  ) : (
                        <DrawCardComponent
                            symbol={activeSymbol.symbolName}
                            onSaveSvg={onSaveSvg}
                            svgString={activeSymbol?.symbolSVGView}
                            isLoadingSaveSymbol={isLoadingSaveSymbol}
                        />
                  )
                )}
                {nextActiveSymbol && (
                    <div className="absolute right-0 top-0 bottom-0 flex items-center cursor-pointer text-20 p-8 mb-20" onClick={onNextSymbol}>
                        {nextActiveSymbol.symbolName}
                        <div className="ml-4">►</div>
                    </div>
                )}
            </div>
        </ModalComponent>
  );
};

export default CreateSymbolModalContainer;
