export const statsUrl = '/api/stats/';
export const generateFontUrl = '/api/generate-font/';
export const saveSymbolUrl = '/api/save-symbol/';
export const downloadFontUrl = '/api/download-font/';
export const fontsUrl = '/api/fonts/';
export const createFontUrl = '/api/create-font/';
export const updateFontUrl = '/api/update-font/';
export const loginUrl = '/api/login/';
export const registerUrl = '/api/register/';
export const payUrl = '/api/pay/';
export const payCryptoUrl = '/api/payCrypto/';
export const checkPayStatusCryptoUrl = '/api/checkPayStatusCrypto/';
export const setPayedStatusCryptoUrl = '/api/setPayedStatusCrypto/';
