import React, { FC } from 'react';
import InputComponent from '../../../core/components/input/InputComponent';
import { PanelSubSectionComponent } from '../../../core/components/panel/PanelComponent';
import ButtonComponent from '../../../core/components/button/ButtonComponent';
import { useAuth, useRegister } from '../useAuth';

type RegisterContainerType = {
  onCloseModal: () => void;
};

export const RegisterContainer: FC<RegisterContainerType> = ({ onCloseModal }) => {
  const {
    isLoadingRegister, register, registerError, setRegisterError
  } = useRegister();

  const {
    email,
    emailError,
    isValidEmail,
    password,
    passwordError,
    isValidPassword,
    name,
    onChangeEmail,
    onChangePassword,
    onChangeName,
  } = useAuth();

  const onRegister = async () => {
    try {
      await register(name, email, password);
      onCloseModal();
      setRegisterError(undefined);
    } catch (error) {
      if (error instanceof Error) {
        setRegisterError(error.message);
      }
    }
  };

  return (
        <>
            <PanelSubSectionComponent>
                <p className="mb-4">Введите имя:</p>
                <InputComponent onChange={onChangeName} value={name} placeholder="Имя" />
            </PanelSubSectionComponent>
            <PanelSubSectionComponent>
                <p className="mb-4">Введите email:</p>
                <InputComponent onChange={onChangeEmail} value={email} placeholder="Email" errorText={emailError} />
            </PanelSubSectionComponent>
            <PanelSubSectionComponent>
                <p className="mb-4">Введите пароль:</p>
                <InputComponent onChange={onChangePassword} value={password} placeholder="Пароль" type="password" errorText={passwordError} />
            </PanelSubSectionComponent>
            {registerError && <div className="text-danger text-14">{registerError}</div>}
            <ButtonComponent
                className="mt-16"
                text="Зарегистрироваться"
                onClick={onRegister}
                isLoading={isLoadingRegister}
                isDisabled={isLoadingRegister || !name.length || !isValidEmail || !isValidPassword}
            />
        </>
  );
};
