import { FontCreatorResponseType, SymbolResponseType } from './fontCreatorResponseTypes';
import { FontCreatorType, SymbolType } from './fontCreatorTypes';
import { alphabets } from './constants';

export const symbolAdapter = ({
  id,
  name,
  code,
  fontid,
  svgview,
  svgcontent,
}: SymbolResponseType): SymbolType => ({
  symbolId: id,
  symbolName: name,
  symbolCode: code,
  symbolSVGView: svgview,
  symbolSVGContent: svgcontent,
  fontId: fontid,
});

export const symbolsAdapter = (symbols: SymbolResponseType[], alphabetCode: string): SymbolType[] => {
  const alphabetSymbols = alphabets[alphabetCode];

  return Object.values(alphabetSymbols).map((symbolCode) => {
    const symbol = symbols.find(({ code }) => code === symbolCode.toString()) as SymbolResponseType;

    return symbolAdapter(symbol);
  });
};

export const fontAdapter = ({
  id,
  name,
  alphabetcode,
  cangenerate,
  canpayment,
  candownload,
  isshow,
  iswrap,
  symbols = [],
  orderid,
}: FontCreatorResponseType): FontCreatorType => ({
  fontId: id,
  fontName: name,
  alphabetCountryCode: alphabetcode,
  canGenerateFont: cangenerate,
  canPaymentFont: canpayment,
  canDownloadFont: candownload,
  isShowSymbols: isshow,
  isWrapSymbols: iswrap,
  symbols: symbolsAdapter(symbols, alphabetcode),
  orderId: orderid,
});

export const fontsAdapter = (fonts: FontCreatorResponseType[]): FontCreatorType[] => fonts.map(fontAdapter);
