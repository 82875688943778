import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import styles from './panelComponent.module.css';

type PropsType = {
  children: ReactNode;
  isSticky?: boolean;
};

export const PanelSubSectionComponent: FC<PropsType> = ({ children }) => (
    <div className={styles.subSectionContainer}>
        {children}
    </div>
);

export const PanelSectionComponent: FC<PropsType> = ({ children, isSticky }) => (
    <div className={classNames(styles.sectionContainer, { [styles.isSticky]: isSticky })}>
        {children}
    </div>
);

const PanelComponent: FC<PropsType> = ({ children }) => {
  const [isClose, setClose] = useState(true);

  const onToggleClose = () => setClose(!isClose);

  return (
        <div className={classNames(styles.container, { [styles.isClose]: isClose })}>
            <div className={styles.visibleButton} onClick={onToggleClose}>{isClose ? 'Показать' : 'Скрыть'} настройки</div>
            <div className={styles.wrapper}>{children}</div>
        </div>
  );
};

export default PanelComponent;
