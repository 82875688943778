import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSymbolUrl } from '@pisaka/core/endpointUrls/endpointUrls';
import { AppStateTypes } from '../../../../store/appStateTypes';
import { SymbolResponseType } from '../fontCreatorResponseTypes';
import { saveSymbolTypeAction } from '../state/actions';
import { symbolAdapter } from '../fontCreatorAdapter';
import { axiosInstance } from '../../../../helpers/axiosInstance';

export type UseSymbolType = {
  symbolCode: string;
  fontId: number;
};

export const useSymbol = ({ symbolCode, fontId }: UseSymbolType) => {
  const [activeSymbolCode, setActiveSymbolCode] = useState(symbolCode);

  const fonts = useSelector((state: AppStateTypes) => state.fontCreator);

  const currentFont = fonts.find((font) => font.fontId === fontId);

  const currentSymbols = currentFont?.symbols;

  const activeSymbol = currentSymbols?.find((currentSymbol) => +currentSymbol.symbolCode === +activeSymbolCode);

  const activeSymbolIndex = currentSymbols?.findIndex((currentSymbol) => +currentSymbol.symbolCode === +activeSymbolCode);

  const prevActiveSymbol = currentSymbols && activeSymbolIndex !== undefined ? currentSymbols[activeSymbolIndex - 1] : undefined;
  const nextActiveSymbol = currentSymbols && activeSymbolIndex !== undefined ? currentSymbols[activeSymbolIndex + 1] : undefined;

  const onPrevSymbol = () => prevActiveSymbol && setActiveSymbolCode(prevActiveSymbol.symbolCode);
  const onNextSymbol = () => nextActiveSymbol && setActiveSymbolCode(nextActiveSymbol.symbolCode);

  return {
    activeSymbol,
    prevActiveSymbol,
    nextActiveSymbol,
    onPrevSymbol,
    onNextSymbol,
  };
};

export const useSaveSymbol = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  const [isLoadingSaveSymbol, setIsLoadingSaveSymbol] = useState(false);

  const saveSymbol = async (id: number, svgView: string, svgContent: string) => {
    try {
      setIsLoadingSaveSymbol(true);

      const { data: { symbol } } = await axiosInstance(token).post<{ symbol: SymbolResponseType }>(saveSymbolUrl, {
        id,
        svgView,
        svgContent,
      });

      if (symbol) {
        dispatch(saveSymbolTypeAction(symbolAdapter(symbol)));
      }
    } finally {
      setIsLoadingSaveSymbol(false);
    }
  };

  return {
    isLoadingSaveSymbol,
    saveSymbol
  };
};
