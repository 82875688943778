import { Action } from 'redux';
import { StatsEnum } from './actionsTypes';
import { StatsType } from '../statsTypes';

interface SetStatsActionType extends Action, StatsType {
  type: StatsEnum.SET_STATS;
}

interface UpdateStatsFontsActionType extends Action {
  type: StatsEnum.INCREMENT_FONTS_COUNTER;
}

interface UpdateStatsUsersActionType extends Action {
  type: StatsEnum.INCREMENT_USERS_COUNTER;
}

export type StatsActionType = SetStatsActionType | UpdateStatsFontsActionType | UpdateStatsUsersActionType;

export const setStatsAction = (statsProps: StatsType): SetStatsActionType => ({
  type: StatsEnum.SET_STATS,
  ...statsProps,
});

export const incrementStatsFontsAction = (): UpdateStatsFontsActionType => ({
  type: StatsEnum.INCREMENT_FONTS_COUNTER,
});

export const incrementStatsUsersAction = (): UpdateStatsUsersActionType => ({
  type: StatsEnum.INCREMENT_USERS_COUNTER,
});
