import { useEffect, useState } from 'react';
import {
  checkPayStatusCryptoUrl,
  createFontUrl,
  downloadFontUrl,
  fontsUrl,
  generateFontUrl, payCryptoUrl, payUrl,
  updateFontUrl
} from '@pisaka/core/endpointUrls/endpointUrls';
import { useDispatch, useSelector } from 'react-redux';
import { createFontTypeAction, setFontsTypeAction, updateFontTypeAction } from '../state/actions';
import { FontCreatorResponseType } from '../fontCreatorResponseTypes';
import { fontAdapter, fontsAdapter } from '../fontCreatorAdapter';
import { alphabets } from '../constants';
import { FontCreatorType } from '../fontCreatorTypes';
import { axiosInstance } from '../../../../helpers/axiosInstance';
import { AppStateTypes } from '../../../../store/appStateTypes';

export const useGetFonts = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  const [isLoadingFonts, setIsLoadingFonts] = useState(false);

  const loadingFonts = async () => {
    try {
      setIsLoadingFonts(true);

      const { data: { fonts } } = await axiosInstance(token).post<{ fonts: FontCreatorResponseType[] }>(fontsUrl);

      if (fonts) {
        dispatch(setFontsTypeAction(fontsAdapter(fonts)));
      }
    } finally {
      setIsLoadingFonts(false);
    }
  };

  useEffect(() => {
    loadingFonts();
  }, []);

  return {
    isLoadingFonts
  };
};

export const useCreateFont = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  const [isLoadingCreateFont, setIsLoadingCreateFont] = useState(false);

  const createFont = async (name: string, alphabetCode: string) => {
    try {
      setIsLoadingCreateFont(true);

      const symbols = alphabets[alphabetCode]
        ? Object.values(alphabets[alphabetCode]).map((symbolCode) => ({
          name: String.fromCharCode(Number(symbolCode)),
          code: symbolCode.toString(),
        }))
        : [];

      const { data: { font } } = await axiosInstance(token).post<{ font: FontCreatorResponseType }>(createFontUrl, {
        name,
        alphabetCode,
        symbols,
      });

      if (font) {
        dispatch(createFontTypeAction(fontAdapter(font)));
      }
    } finally {
      setIsLoadingCreateFont(false);
    }
  };

  return {
    isLoadingCreateFont,
    createFont
  };
};

type UpdateFontType = Pick<FontCreatorType, 'fontId'> & Partial<Pick<FontCreatorType, 'isShowSymbols' | 'canGenerateFont' | 'canPaymentFont' | 'canDownloadFont' | 'isWrapSymbols' | 'orderId'>>;

export const useUpdateFont = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  const [isLoadingUpdateFont, setIsLoadingUpdateFont] = useState(false);

  const updateFont = async ({
    fontId, isShowSymbols, isWrapSymbols, canPaymentFont, canDownloadFont, canGenerateFont, orderId
  }: UpdateFontType) => {
    try {
      setIsLoadingUpdateFont(true);

      const { data: { font } } = await axiosInstance(token).post<{ font: FontCreatorResponseType }>(updateFontUrl, {
        canDownload: canDownloadFont,
        canGenerate: canGenerateFont,
        canPayment: canPaymentFont,
        isShow: isShowSymbols,
        isWrap: isWrapSymbols,
        fontId,
        orderId
      });

      if (font) {
        dispatch(updateFontTypeAction(fontAdapter(font)));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingUpdateFont(false);
    }
  };

  return {
    isLoadingUpdateFont,
    updateFont
  };
};

export const useGenerateFont = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  const [isLoadingGenerateFont, setIsLoadingGenerateFont] = useState(false);

  const generateFont = async (fontId: number) => {
    try {
      setIsLoadingGenerateFont(true);

      const { data: { font } } = await axiosInstance(token).post<{ font: FontCreatorResponseType }>(generateFontUrl, {
        fontId,
      });

      if (font) {
        dispatch(updateFontTypeAction(fontAdapter(font)));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingGenerateFont(false);
    }
  };

  return {
    isLoadingGenerateFont,
    generateFont
  };
};

export const useDownloadFont = () => {
  const [isLoadingDownloadFont, setIsLoadingDownloadFont] = useState(false);

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  const downloadFont = async (fontId: number) => {
    try {
      setIsLoadingDownloadFont(true);

      const { data } = await axiosInstance(token).post<string>(downloadFontUrl, {
        fontId,
      }, { responseType: 'blob' });

      return data;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingDownloadFont(false);
    }
  };

  return {
    isLoadingDownloadFont,
    downloadFont
  };
};

type PayFontResponseType = {
  paymentConfirmationToken?: string,
  status?: 'waiting_for_capture' | 'pending' | 'succeeded' | 'canceled',
};

export const usePayFont = () => {
  const [isLoadingPayFont, setIsLoadingPayFont] = useState(false);

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  const payFont = async (fontId: number) => {
    try {
      setIsLoadingPayFont(true);

      const { data: { paymentConfirmationToken, status } } = await axiosInstance(token).post<PayFontResponseType>(payUrl, {
        fontId
      });

      return { paymentConfirmationToken, status };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingPayFont(false);
    }
  };

  return {
    isLoadingPayFont,
    payFont
  };
};

type PayCryptoResponseType = {
  'status': 'success' | 'error',
  'pay_url': string,
  'invoice_id': string,
};

export const usePayCryptoFont = () => {
  const [isLoadingPayCryptoFont, setIsLoadingPayCryptoFont] = useState(false);

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  const payCryptoFont = async (fontId: number) => {
    try {
      setIsLoadingPayCryptoFont(true);

      const { data } = await axiosInstance(token).post<PayCryptoResponseType>(payCryptoUrl, {
        fontId
      });

      const { status, pay_url, invoice_id } = data;

      return { status, payUrl: pay_url, invoiceId: invoice_id };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingPayCryptoFont(false);
    }
  };

  return {
    isLoadingPayCryptoFont,
    payCryptoFont
  };
};

type CheckPayStatusCryptoResponseType = {
  'status': 'success' | 'error',
  'status_invoice': 'created' | 'paid',
};

export const useCheckPayStatusCryptoFont = () => {
  const [isCheckPayStatusCryptoFont, setIsCheckPayStatusCryptoFont] = useState(false);

  const { token } = useSelector((state: AppStateTypes) => state.auth) || {};

  const checkPayStatusCryptoFont = async (orderId?: string) => {
    try {
      setIsCheckPayStatusCryptoFont(true);

      const { data } = await axiosInstance(token).post<CheckPayStatusCryptoResponseType>(checkPayStatusCryptoUrl, {
        orderId
      });

      const { status, status_invoice } = data;

      return { status, statusInvoice: status_invoice };
    } catch (e) {
      console.error(e);
    } finally {
      setIsCheckPayStatusCryptoFont(false);
    }
  };

  return {
    isCheckPayStatusCryptoFont,
    checkPayStatusCryptoFont
  };
};
