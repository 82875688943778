import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import perfumePlugin from '@analytics/perfumejs';
import Perfume from 'perfume.js';

const analytics = Analytics({
  app: 'my-app',
  plugins: [
    googleAnalytics({
      trackingId: 'UA-148982785-1',
      instanceName: 'two'
    }),
    perfumePlugin({
      perfume: Perfume,
      destinations: {
        'google-analytics': true,
      },
    }),
  ]
});

analytics.page();
