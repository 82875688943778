import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './headerComponent.module.css';
import { fontCreatorPageUrl } from '../../../helpers/urls';

type PropsType = {
  toggleSidebar: () => void;
};

const HeaderComponent: FC<PropsType> = ({ toggleSidebar }) => (
    <div className={styles.container}>
        <div className="flex items-center">
            <div className="flex flex-col justify-between h-42 w-42 py-12 px-6 cursor-pointer relative" onClick={toggleSidebar}>
                <div className="w-full h-3 bg-black" />
                <div className="w-full h-3 bg-black" />
                <div className="w-full h-3 bg-black" />
                <div className="absolute h-6 w-6 bg-danger top-10 right-4 rounded-3" />
            </div>
            <div className={styles.flex}>
                <a href="/" className={styles.logo}></a>
            </div>
        </div>
        <div className={styles.flex}>
            <Link to={fontCreatorPageUrl} className="text-14 block text-primary text-right leading-18">
                Создать свой рукописный шрифт
            </Link>
        </div>
    </div>
);

export default HeaderComponent;
