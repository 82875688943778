import React, { FC, useEffect, useRef } from 'react';
import FontCreatorAuthContainer from './fontCreatorAuth/FontCreatorAuthContainer';
import FontCreatorNotAuthContainer from './fontCreatorNotAuth/FontCreatorNotAuthContainer';
import { useAuth } from '../auth/useAuth';

const FontCreatorContainer: FC = () => {
  const { isAuth } = useAuth();

  const refAd = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refAd) {
      // @ts-ignore
      window.yaContextCb.push(() => {
        // @ts-ignore
        Ya.Context.AdvManager.render({
          blockId: 'R-A-3642064-1',
          renderTo: 'yandex_rtb_R-A-3642064-1'
        });
      });
    }
  }, [refAd]);

  return (
        <>
            <div ref={refAd} id="yandex_rtb_R-A-3642064-1" className="mt-20"></div>
            {isAuth ? <FontCreatorAuthContainer /> : <FontCreatorNotAuthContainer />}
        </>
  );
};

export default FontCreatorContainer;
