import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './buttonComponent.module.css';
import { LoaderComponent } from '../loader/LoaderComponent';

export enum ButtonSizeEnum {
  MD = 'md',
  SM = 'sm',
  XS = 'xs',
}

type PropsType = {
  text: string;
  onClick?: () => void;
  className?: string;
  classNameText?: string;
  isDisabled?: boolean;
  size?: ButtonSizeEnum;
  isLoading?: boolean;
  isOutline?: boolean;
};

const ButtonComponent: FC<PropsType> = ({
  text, onClick, className, isDisabled = false, size = ButtonSizeEnum.MD, isLoading, isOutline = false, classNameText
}: PropsType) => (
    <div className={classNames(className, 'relative')}>
        {isLoading && <LoaderComponent />}
        <button
            onClick={onClick}
            className={classNames(styles.button, 'whitespace-nowrap', classNameText, {
              'p-10 text-16': size === ButtonSizeEnum.MD,
              'p-6 text-14': size === ButtonSizeEnum.SM,
              'p-4 text-12': size === ButtonSizeEnum.XS,
              'opacity-50 !cursor-not-allowed': isDisabled,
              '!text-primary !bg-white': isOutline,
            })}
            disabled={isDisabled}
        >
            {text}
        </button>
    </div>
);

export default ButtonComponent;
