import React, { FC, useState } from 'react';
import { useModal } from '../../../../hooks/useModal';
import { MODAL_NAME_CREATE_FONT } from '../../../../constants/modalNamesConstants';
import ModalComponent from '../../../../core/components/modal/ModalComponent';
import { PanelSubSectionComponent } from '../../../../core/components/panel/PanelComponent';
import SelectComponent from '../../../../core/components/select/SelectComponent';
import InputComponent from '../../../../core/components/input/InputComponent';
import ButtonComponent from '../../../../core/components/button/ButtonComponent';
import { useCreateFont } from './useFonts';

const CreateFontModalContainer: FC = () => {
  const { onCloseModal } = useModal(MODAL_NAME_CREATE_FONT);

  const [fontName, setFontName] = useState('');
  const [alphabetCountryCode, setAlphabetCountryCode] = useState('ru');

  const onFontNameChange = (value: string) => setFontName(value);
  const onAlphabetChange = (value: string) => setAlphabetCountryCode(value);

  const { isLoadingCreateFont, createFont } = useCreateFont();

  const alphabetOptions = [
    {
      text: 'Русский',
      value: 'ru',
    },
    {
      text: 'Английский',
      value: 'en',
    },
    {
      text: 'Украинский',
      value: 'ua',
    },
    {
      text: 'Белорусский',
      value: 'by',
    },
    {
      text: 'Башкирский',
      value: 'ru-ba',
    },
    {
      text: 'Татарский',
      value: 'ru-ta',
    },
    {
      text: 'Казахский',
      value: 'kz',
    },
    {
      text: 'Армянский',
      value: 'am',
    },
    {
      text: 'Грузинский',
      value: 'ge',
    },
    {
      text: 'Турецкий',
      value: 'tr',
    },
  ];

  const onClickCreateFont = async () => {
    if (fontName.length && alphabetCountryCode.length) {
      await createFont(fontName, alphabetCountryCode);
      onCloseModal();
    }
  };

  return (
        <ModalComponent title="Создать новый шрифт" onClose={onCloseModal}>
            <PanelSubSectionComponent>
                <p className="mb-4">Введите имя шрифта:</p>
                <InputComponent onChange={onFontNameChange} value={fontName} placeholder="Имя шрифта" />
            </PanelSubSectionComponent>
            <PanelSubSectionComponent>
                <p className="mb-4">Выберите алфавит:</p>
                <SelectComponent onChange={onAlphabetChange} defaultValue={alphabetCountryCode} options={alphabetOptions} />
            </PanelSubSectionComponent>
            <ButtonComponent className="mt-16" text="Создать шрифт" onClick={onClickCreateFont} isLoading={isLoadingCreateFont} />
        </ModalComponent>
  );
};

export default CreateFontModalContainer;
