import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AppStateTypes } from '../../../store/appStateTypes';
import EmptyFontCreatorContainer from './EmptyFontCreatorContainer';
import FontCreatorControlPanelContainer from './FontCreatorControlPanelContainer';
import { useModal } from '../../../hooks/useModal';
import {
  MODAL_NAME_CREATE_FONT,
  MODAL_NAME_CREATE_SYMBOL,
  PAYMENT_MODAL_NAME
} from '../../../constants/modalNamesConstants';
import CreateFontModalContainer from './font/CreateFontModalContainer';
import FontContainer from './font/FontContainer';
import CreateSymbolModalContainer from './symbol/CreateSymbolModalContainer';
import { useCheckPayStatusCryptoFont, useGetFonts, useUpdateFont } from './font/useFonts';
import { LoaderComponent } from '../../../core/components/loader/LoaderComponent';
import PaymentModalContainer from './payment/PaymentModalContainer';

const FontCreatorAuthContainer: FC = () => {
  const { isLoadingFonts } = useGetFonts();

  const fontCreator = useSelector((state: AppStateTypes) => state.fontCreator);

  const { isOpenModal: isOpenCreateFontModal } = useModal(MODAL_NAME_CREATE_FONT);
  const { isOpenModal: isOpenCreateSymbolModal } = useModal(MODAL_NAME_CREATE_SYMBOL);
  const { isOpenModal: isOpenPaymentModal } = useModal(PAYMENT_MODAL_NAME);

  const [searchParams] = useSearchParams();
  const isNewPayment = searchParams.get('isNewPayment');

  const { updateFont } = useUpdateFont();
  const { checkPayStatusCryptoFont } = useCheckPayStatusCryptoFont();

  useEffect(() => {
    if (isNewPayment && fontCreator.length) {
      const updateStatusPayedWithCrypto = () => {
        fontCreator.forEach(async ({ fontId, orderId }) => {
          if (orderId) {
            const { statusInvoice } = await checkPayStatusCryptoFont(orderId) || {};

            if (statusInvoice === 'paid' && orderId) {
              await updateFont({
                fontId,
                canDownloadFont: true,
                canPaymentFont: false,
                orderId: undefined
              });
            }
          }
        });
      };

      updateStatusPayedWithCrypto();
    }
  }, [isNewPayment, fontCreator]);

  return (
        <div className="relative">
            <FontCreatorControlPanelContainer />
            {isLoadingFonts && <LoaderComponent />}
            {!fontCreator.length ? <EmptyFontCreatorContainer /> : (
                <div className="px-16">
                    {fontCreator.map((font) => <FontContainer key={font.fontId} {...font} />)}
                </div>
            )}
            {isOpenCreateFontModal && <CreateFontModalContainer />}
            {isOpenCreateSymbolModal && (
                <CreateSymbolModalContainer />
            )}
            {isOpenPaymentModal && <PaymentModalContainer />}
        </div>
  );
};

export default FontCreatorAuthContainer;
