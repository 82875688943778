import React, { FC } from 'react';
import ButtonComponent from '../../../core/components/button/ButtonComponent';
import { useModal } from '../../../hooks/useModal';
import { MODAL_NAME_AUTH } from '../../../constants/modalNamesConstants';

const FontCreatorNotAuthControlPanelContainer: FC = () => {
  const { onOpenModal } = useModal(MODAL_NAME_AUTH);

  return (
        <div className="flex justify-end p-16">
            <ButtonComponent text="Создать новый шрифт" onClick={onOpenModal} />
        </div>
  );
};

export default FontCreatorNotAuthControlPanelContainer;
