import { Action } from 'redux';
import { ModalActionListEnum } from './actionsTypes';

interface OpenModalTypeActionType extends Action {
  type: ModalActionListEnum.OPEN;
  modalName: string;
  payload?: any;
}

interface CloseModalTypeActionType extends Action {
  type: ModalActionListEnum.CLOSE;
  modalName: string;
}

export type ModalActionType = OpenModalTypeActionType | CloseModalTypeActionType;

export const openModalTypeAction = (modalName: string, payload: any): OpenModalTypeActionType => ({
  type: ModalActionListEnum.OPEN,
  modalName,
  payload,
});

export const closeModalTypeAction = (modalName: string): CloseModalTypeActionType => ({
  type: ModalActionListEnum.CLOSE,
  modalName,
});
