export const arabicNumerals = {
  'U+0030': 48, // 0
  'U+0031': 49, // 1
  'U+0032': 50, // 2
  'U+0033': 51, // 3
  'U+0034': 52, // 4
  'U+0035': 53, // 5
  'U+0036': 54, // 6
  'U+0037': 55, // 7
  'U+0038': 56, // 8
  'U+0039': 57, // 9
};
