import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import ModalComponent from '../../../../core/components/modal/ModalComponent';
import { useModal } from '../../../../hooks/useModal';
import { PAYMENT_MODAL_NAME } from '../../../../constants/modalNamesConstants';
import ButtonComponent, { ButtonSizeEnum } from '../../../../core/components/button/ButtonComponent';
import {
  useCheckPayStatusCryptoFont, usePayCryptoFont, usePayFont, useUpdateFont
} from '../font/useFonts';
import { AppStateTypes } from '../../../../store/appStateTypes';

const PaymentModalContainer: FC = () => {
  const { onCloseModal, payload } = useModal(PAYMENT_MODAL_NAME);

  const fontCreator = useSelector((state: AppStateTypes) => state.fontCreator);

  const { fontId } = payload;

  const currentFont = fontCreator.find((font) => Number(font.fontId) === Number(fontId));

  const { isLoadingUpdateFont, updateFont } = useUpdateFont();
  const { isLoadingPayFont, payFont } = usePayFont();
  const { isLoadingPayCryptoFont, payCryptoFont } = usePayCryptoFont();
  const { isCheckPayStatusCryptoFont, checkPayStatusCryptoFont } = useCheckPayStatusCryptoFont();

  const buttonProps = {
    text: 'Купить шрифт за 99₽',
    onClick: async () => {
      const { paymentConfirmationToken, status } = await payFont(fontId) || {};

      if (paymentConfirmationToken) {
        // @ts-ignore
        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: paymentConfirmationToken, // Токен, который перед проведением оплаты нужно получить от ЮKassa
          // return_url: 'http://localhost:3000/font-creator/', //Ссылка на страницу завершения оплаты

          // Настройка виджета
          customization: {
            // Настройка способа отображения
            modal: true
          },
          error_callback() {
            // Обработка ошибок инициализации
          }
        });

        checkout.render();

        checkout.on('success', async () => {
          await updateFont({
            fontId,
            canPaymentFont: false,
            canDownloadFont: true,
          });

          checkout.destroy();
        });

        checkout.on('fail', () => {
          // Код, который нужно выполнить после неудачной оплаты.

          // Удаление инициализированного виджета
          checkout.destroy();
        });
      }

      if (status === 'succeeded') {
        await updateFont({
          fontId,
          canPaymentFont: false,
          canDownloadFont: true,
        });

        onCloseModal();
      }
    },
    isLoading: isLoadingPayFont || isLoadingUpdateFont
  };

  const buttonPropsCrypto = {
    text: 'Купить шрифт за $2.59',
    onClick: async () => {
      if (currentFont?.orderId) {
        const { statusInvoice } = await checkPayStatusCryptoFont(currentFont?.orderId) || {};

        if (statusInvoice === 'created') {
          window.location.href = `https://pay.cryptocloud.plus/${currentFont?.orderId}`;
        }
      } else {
        const { status, payUrl, invoiceId } = await payCryptoFont(fontId) || {};

        if (status === 'success') {
          await updateFont({
            fontId,
            orderId: invoiceId,
          });

          if (payUrl) {
            window.location.href = payUrl;
          }
        }
      }
    },
    isLoading: isLoadingPayCryptoFont || isCheckPayStatusCryptoFont
  };

  return (
        <ModalComponent title="Купить шрифт" onClose={onCloseModal}>
            <div className="flex items-center justify-center -m-16">
              <div className="p-16 w-6/12 border-r border-solid border-gray">
                <div className="font-semibold mb-8">Оплата шрифта с карты российского банка:</div>
                <ButtonComponent {...buttonProps} size={ButtonSizeEnum.SM} />
              </div>
              <div className="p-16 w-6/12">
                <div className="font-semibold mb-8">Оплата шрифта с помощью криптовалюты:</div>
                <ButtonComponent {...buttonPropsCrypto} size={ButtonSizeEnum.SM} />
              </div>
            </div>
        </ModalComponent>
  );
};

export default PaymentModalContainer;
