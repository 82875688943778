export const armenianAlphabet = {
  'U+0531': 1329, // Ա
  'U+0561': 1377, // ա
  'U+0532': 1330, // Բ
  'U+0562': 1378, // բ
  'U+0533': 1331, // Գ
  'U+0563': 1379, // գ
  'U+0534': 1332, // Դ
  'U+0564': 1380, // դ
  'U+0535': 1333, // Ե
  'U+0565': 1381, // ե
  'U+0536': 1334, // Զ
  'U+0566': 1382, // զ
  'U+0537': 1335, // Է
  'U+0567': 1383, // է
  'U+0538': 1336, // Ը
  'U+0568': 1384, // ը
  'U+0539': 1337, // Թ
  'U+0569': 1385, // թ
  'U+053A': 1338, // Ժ
  'U+056A': 1386, // ժ
  'U+053B': 1339, // Ի
  'U+056B': 1387, // ի
  'U+053C': 1340, // Լ
  'U+056C': 1388, // լ
  'U+053D': 1341, // Խ
  'U+056D': 1389, // խ
  'U+053E': 1342, // Ծ
  'U+056E': 1390, // ծ
  'U+053F': 1343, // Կ
  'U+056F': 1391, // կ
  'U+0540': 1344, // Հ
  'U+0570': 1392, // հ
  'U+0541': 1345, // Ձ
  'U+0571': 1393, // ձ
  'U+0542': 1346, // Ղ
  'U+0572': 1394, // ղ
  'U+0543': 1347, // Ճ
  'U+0573': 1395, // ճ
  'U+0544': 1348, // Մ
  'U+0574': 1396, // մ
  'U+0545': 1349, // Յ
  'U+0575': 1397, // յ
  'U+0546': 1350, // Ն
  'U+0576': 1398, // ն
  'U+0547': 1351, // Շ
  'U+0577': 1399, // շ
  'U+0548': 1352, // Ո
  'U+0578': 1400, // ո
  'U+0549': 1353, // Չ
  'U+0579': 1401, // չ
  'U+054A': 1354, // Պ
  'U+057A': 1402, // պ
  'U+054B': 1355, // Ջ
  'U+057B': 1403, // ջ
  'U+054C': 1356, // Ռ
  'U+057C': 1404, // ռ
  'U+054D': 1357, // Ս
  'U+057D': 1405, // ս
  'U+054E': 1358, // Վ
  'U+057E': 1406, // վ
  'U+054F': 1359, // Տ
  'U+057F': 1407, // տ
  'U+0550': 1360, // Ր
  'U+0580': 1408, // ր
  'U+0551': 1361, // Ց
  'U+0581': 1409, // ց
  'U+0552': 1362, // Ւ
  'U+0582': 1410, // ւ // TODO: check key
  'U+0553': 1363, // Փ
  'U+0583': 1411, // փ
  'U+0554': 1364, // Ք
  'U+0584': 1412, // ք
  'U+0555': 1365, // Օ
  'U+0585': 1413, // օ
  'U+0556': 1366, // Ֆ
  'U+0586': 1414, // ֆ
};
