export enum TextSettingsActionListEnum {
  CHANGE_FONT_TYPE = 'textSettings:fontType:change',
  CHANGE_FONT_SIZE = 'textSettings:fontSize:change',
  CHANGE_LINE_HEIGHT = 'textSettings:lineHeight:change',
  CHANGE_INDENT_RIGHT = 'textSettings:indentRight:change',
  CHANGE_INDENT_LEFT = 'textSettings:indentLeft:change',
  CHANGE_INDENT_TOP = 'textSettings:indentTop:change',
  CHANGE_TEXT_COLOR = 'textSettings:textColor:change',
  CHANGE_INDENT_RED_LINE = 'textSettings:indentRedLine:change',
  CHANGE_INDENT_LETTER = 'textSettings:indentLetter:change',
  CHANGE_INDENT_WORD = 'textSettings:indentWord:change',
  TOGGLE_RANDOM_FONT_SIZE = 'textSettings:randomFontSize:toggle',
  TOGGLE_RANDOM_ROTATE_LETTER = 'textSettings:randomRotateLetter:toggle',
  TOGGLE_RANDOM_LINE_LEFT_INDENT = 'textSettings:randomLineLeftIndent:toggle',
  RESET_TEXT_SETTINGS = 'textSettings:reset',
}
