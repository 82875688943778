import React, { FC } from 'react';
import { useModal } from '../../../hooks/useModal';
import { MODAL_NAME_CREATE_FONT } from '../../../constants/modalNamesConstants';

const EmptyFontCreatorContainer: FC = () => {
  const { onOpenModal } = useModal(MODAL_NAME_CREATE_FONT);

  return (
        <div className="flex items-center justify-center h-emptyHeightWithInfo px-16">
            <div className="text-center">
                Вы еще не создали ни одного шрифта.{' '}<span className="text-primary cursor-pointer" onClick={onOpenModal}>Создать</span>
            </div>
        </div>
  );
};

export default EmptyFontCreatorContainer;
