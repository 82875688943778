import { combineReducers } from 'redux';
import textSettings from '../modules/textSettings/state/reducer';
import imageSettings from '../modules/imageSettings/state/reducer';
import fontCreator from '../modules/fontCreator/fontCreatorAuth/state/reducer';
import modal from '../modules/modal/state/reducer';
import auth from '../modules/auth/state/reducer';
import stats from '../modules/stats/state/reducer';

export default combineReducers({
  textSettings,
  imageSettings,
  fontCreator,
  modal,
  auth,
  stats,
});
