import axios from 'axios';

export const axiosInstance = (token?: string) => axios.create({
  // TODO: что бы собрать сборку для prod, пока нужно убирать process.env.REACT_APP_BASE_URL
  // потому что сборка фронта на сервере падает из-за недостатка памяти
  // (что бы это пофиксить, нужно покудать план с большей памятью -- это пока не нужно)
  baseURL: process.env.REACT_APP_BASE_URL || '/',
  ...(token ? {
    headers: {
      authorization: `Bearer ${token}`
    },
  } : {}),
});
