import { AuthActionType } from './actions';
import { AuthActionListEnum } from './actionsTypes';
import { AuthType } from '../authTypes';
import { getCookie } from '../../../helpers/cookie';
import { COOKIE_NAME_AUTH_TOKEN } from '../../../constants/cookieNamesConstants';

const authProps = getCookie(COOKIE_NAME_AUTH_TOKEN);

const authInitialState = authProps ? JSON.parse(authProps) : {};

const auth = (state: AuthType = authInitialState, action: AuthActionType) => {
  if (action.type === AuthActionListEnum.LOGIN) {
    const { user, token } = action;

    return {
      user, token
    };
  }

  if (action.type === AuthActionListEnum.LOGOUT) {
    return {};
  }

  return state;
};

export default auth;
