import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

type PropsType = {
  symbol: string;
  onClick?: () => void;
  children?: ReactNode;
  className?: string;
};

export const SymbolComponent: FC<PropsType> = ({
  symbol, onClick, children, className
}: PropsType) => (
    <div
        onClick={onClick}
        className={classNames('relative flex items-center justify-center border border-solid border-gray w-150 min-w-150 h-200 m-16 cursor-pointer', className)}
    >
        <span
            className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center text-133 leading-normal opacity-25 font-extralight font-arial"
            style={{ top: '-10px' }}
        >
            {symbol}
        </span>
        {children}
        <div className="h-1 w-full absolute bg-primary" style={{ top: '45px' }} />
        <div className="h-1 w-full absolute bg-primary" style={{ top: '70px' }} />
        <div className="h-1 w-full absolute bg-primary" style={{ top: '140px' }} />
    </div>
);
