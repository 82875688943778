import React, { FC } from 'react';
import classNames from 'classnames';
import { SymbolComponent } from '../../../../core/components/symbol/SymbolComponent';
import { useModal } from '../../../../hooks/useModal';
import { MODAL_NAME_CREATE_SYMBOL } from '../../../../constants/modalNamesConstants';
import { SymbolType } from '../fontCreatorTypes';

type PropsType = {
  fontId: number;
  symbols: SymbolType[];
  canGenerateFont: boolean;
};

const SymbolsContainer: FC<PropsType> = ({ fontId, symbols, canGenerateFont }: PropsType) => {
  const { onOpenModal } = useModal(MODAL_NAME_CREATE_SYMBOL);

  return (
        <>
            {symbols.map(({ symbolCode, symbolSVGView }) => {
              const symbol = String.fromCharCode(Number(symbolCode));

              const onClickSymbol = () => {
                if (canGenerateFont) {
                  onOpenModal({
                    symbolCode,
                    fontId,
                  });
                }
              };

              let svgUrl = '';

              if (symbolSVGView) {
                const blob = new Blob([symbolSVGView], { type: 'image/svg+xml' });

                svgUrl = URL.createObjectURL(blob);
              }

              return (
                    <SymbolComponent
                        key={`${symbolCode}-${fontId}`}
                        symbol={symbol}
                        onClick={onClickSymbol}
                        className={classNames({ '!cursor-default': !canGenerateFont })}>
                        <img src={svgUrl} />
                    </SymbolComponent>
              );
            })}
        </>
  );
};

export default SymbolsContainer;
