export type TextSettingsTypes = {
  fontType: FontTypeEnum
  textColor: TextColorEnum
  fontSize: number
  lineHeight: number
  indentRight: number
  indentLeft: number
  indentTop: number
  indentRedLine: number
  indentLetter: number
  indentWord: number
  isRandomFontSize: boolean
  isRandomRotateLetter: boolean
  isRandomLineLeftIndent: boolean
};

export enum FontTypeEnum {
  ABRAM = 'Abram',
  ANSELMO = 'Anselmo',
  BENVOLIO = 'Benvolio',
  CAPULETTY = 'Capuletty',
  DJIOVANNI = 'Djiovanni',
  ESKAL = 'Eskal',
  GREGORY = 'Gregory',
  LORENCO = 'Lorenco',
  MERKUCIO = 'Merkucio',
  MONTEKKY = 'Montekky',
  PAG = 'Pag',
  PARIS = 'Paris',
  SALAVAT = 'Salavat',
  SAMSON = 'Samson',
  STEFANO = 'Stefano',
  TIBALT = 'Tibalt',
  // BUTEEV = 'Buteev', -- нерабочая ссылка для скачивания файла шрифта
  LEXA = 'Lexa',
}

export enum TextColorEnum {
  BLUE = '#00008b',
  BLACK = '#000',
  RED = '#db4760'
}

export enum IndentRedLineEnum {
  CAGE = 75,
  LINE = 83,
  EMPTY = 0,
  CUSTOM = 0,
}
