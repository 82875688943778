import { useDispatch, useSelector } from 'react-redux';
import { AppStateTypes } from '../store/appStateTypes';
import { closeModalTypeAction, openModalTypeAction } from '../modules/modal/state/actions';

export const useModal = (modalName: string) => {
  const dispatch = useDispatch();

  const modal = useSelector((state: AppStateTypes) => state.modal);

  const isOpenModal = modal[modalName] ? modal[modalName].isOpen : false;
  const payload = modal[modalName] ? modal[modalName].payload : undefined;

  const onOpenModal = (payloadData?: any) => dispatch(openModalTypeAction(modalName, payloadData));
  const onCloseModal = () => dispatch(closeModalTypeAction(modalName));

  return {
    isOpenModal,
    onOpenModal,
    onCloseModal,
    payload,
  };
};
