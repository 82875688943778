import './index.css';
import './core/styles/resetStyles.module.css';

import './helpers/socket';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';
import FontCreatorPageContainer from './pages/fontCreatorPage/FontCreatorPageContainer';
import {
  basePageUrl, faqPageUrl, fontCreatorPageUrl, offerPageUrl, policyPageUrl
} from './helpers/urls';
import { AsyncMainPageContainer } from './pages/mainPage/AsyncMainPageContainer';
import { LoaderComponent } from './core/components/loader/LoaderComponent';
import { AsyncOfferPageContainer } from './pages/offerPage/AsyncOfferPageContainer';
import { AsyncPolicyPageContainer } from './pages/policyPage/AsyncPolicyPageContainer';
import './analytics';
import { AsyncFaqPageContainer } from './pages/faqPage/AsyncFaqPageContainer';

const store = configureStore();

ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
          <Suspense fallback={<LoaderComponent />}>
              <Routes>
                  <Route path={basePageUrl} element={<AsyncMainPageContainer />} />
                  <Route path={fontCreatorPageUrl} element={<FontCreatorPageContainer />} />
                  <Route path={offerPageUrl} element={<AsyncOfferPageContainer />} />
                  <Route path={faqPageUrl} element={<AsyncFaqPageContainer />} />
                  {/* <Route path={policyPageUrl} element={<AsyncPolicyPageContainer />} /> */}
              </Routes>
          </Suspense>
      </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
