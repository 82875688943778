import { FontCreatorType } from '../fontCreatorTypes';
import { FontCreatorActionType } from './actions';
import { FontCreatorActionListEnum } from './actionsTypes';

const fontCreator = (state: FontCreatorType[] = [], action: FontCreatorActionType) => {
  if (action.type === FontCreatorActionListEnum.SET_FONTS) {
    const { fonts } = action;

    return fonts;
  }

  if (action.type === FontCreatorActionListEnum.CREATE_FONT) {
    const { type, ...actionData } = action;

    return [
      ...state,
      actionData,
    ];
  }

  if (action.type === FontCreatorActionListEnum.UPDATE_FONT) {
    // TODO: выпилить костыль с symbols
    const { type, symbols, ...actionData } = action;

    const updatedFonts = state.map((font) => {
      if (font.fontId === actionData.fontId) {
        return { ...font, ...actionData };
      }

      return font;
    });

    return [
      ...updatedFonts,
    ];
  }

  if (action.type === FontCreatorActionListEnum.SAVE_SYMBOL) {
    const { type, ...actionData } = action;

    const updatedFonts = state.map((font) => {
      if (font.fontId === actionData.fontId) {
        let updatedSymbols = font.symbols;

        const currentSymbol = font.symbols.find(({ symbolCode }) => symbolCode === actionData.symbolCode);

        if (currentSymbol) {
          updatedSymbols = updatedSymbols.map((symbol) => {
            if (symbol.symbolCode === actionData.symbolCode) {
              return {
                ...symbol,
                symbolSVGView: actionData.symbolSVGView,
                symbolSVGContent: actionData.symbolSVGContent,
              };
            }

            return symbol;
          });
        }

        return {
          ...font,
          symbols: updatedSymbols
        };
      }

      return font;
    });

    return updatedFonts;
  }

  return state;
};

export default fontCreator;
