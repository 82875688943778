import React, { FC } from 'react';
import FontComponent from '../../../../core/components/font/FontComponent';
import { FontCreatorType } from '../fontCreatorTypes';
import FontControlsContainer from './FontControlsContainer';
import SymbolsContainer from '../symbol/SymbolsContainer';
import { useUpdateFont } from './useFonts';

type PropsType = FontCreatorType;

const FontContainer: FC<PropsType> = ({
  fontId,
  fontName,
  canDownloadFont,
  canGenerateFont,
  canPaymentFont,
  isShowSymbols,
  symbols,
  alphabetCountryCode,
  isWrapSymbols,
}: FontCreatorType) => {
  const { updateFont } = useUpdateFont();

  const title = `${fontName} (${alphabetCountryCode.toUpperCase()})`;

  const toggleShowSymbols = (isShow: boolean) => updateFont({ fontId, isShowSymbols: isShow });
  const toggleWrapSymbols = (isWrap: boolean) => updateFont({ fontId, isWrapSymbols: isWrap });

  return (
        <FontComponent
            title={title}
            Controls={(
                <FontControlsContainer
                    canDownloadFont={canDownloadFont}
                    canGenerateFont={canGenerateFont}
                    canPaymentFont={canPaymentFont}
                    symbols={symbols}
                    alphabetCountryCode={alphabetCountryCode}
                    fontId={fontId}
                    fontName={fontName}
                />
            )}
            isShowSymbols={isShowSymbols}
            Symbols={<SymbolsContainer fontId={fontId} symbols={symbols} canGenerateFont={canGenerateFont} />}
            isWrapSymbols={isWrapSymbols}
            toggleShowSymbols={toggleShowSymbols}
            toggleWrapSymbols={toggleWrapSymbols}
        />
  );
};

export default FontContainer;
