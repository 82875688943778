import { StatsActionType } from './actions';
import { StatsType } from '../statsTypes';
import { StatsEnum } from './actionsTypes';

const statsInitialState = {
  fontsCounter: 0,
  usersCounter: 0,
};

const stats = (state: StatsType = statsInitialState, action: StatsActionType) => {
  if (action.type === StatsEnum.SET_STATS) {
    const { fontsCounter, usersCounter } = action;

    return {
      fontsCounter, usersCounter
    };
  }

  if (action.type === StatsEnum.INCREMENT_FONTS_COUNTER) {
    return {
      ...state,
      fontsCounter: state.fontsCounter + 1,
    };
  }

  if (action.type === StatsEnum.INCREMENT_USERS_COUNTER) {
    return {
      ...state,
      usersCounter: state.usersCounter + 1
    };
  }

  return state;
};

export default stats;
