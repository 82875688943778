import React, { FC } from 'react';
import ButtonComponent from '../../../core/components/button/ButtonComponent';
import { useModal } from '../../../hooks/useModal';
import { MODAL_NAME_CREATE_FONT } from '../../../constants/modalNamesConstants';

const FontCreatorControlPanelContainer: FC = () => {
  const { onOpenModal } = useModal(MODAL_NAME_CREATE_FONT);

  return (
        <>
            <div className="flex justify-between items-center p-16">
                <h1 className="text-20 font-semibold">Мои шрифты</h1>
                <ButtonComponent text="Создать новый шрифт" onClick={onOpenModal} />
            </div>
            <div className="text-14 leading-10 mt-4 !leading-18 px-16 mb-20">
                Заполнять символы можно с любого устройства:
                <ul className="!list-disc !pl-16 !mt-8">
                    <li>С персонального компьютера или ноутбука при помощи мыши или трекпада;</li>
                    <li>С смартфона или планшета;</li>
                </ul>
                <p className="text-primary mt-8">
                    Самый предпочтительный вариант: использовать смартфон или планшет и стилус.<br />
                    Так вы добьетесь максимальной схожести вашего почерка.
                </p>
            </div>
        </>
  );
};

export default FontCreatorControlPanelContainer;
