import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontCreatorType } from '../fontCreatorTypes';
import ButtonComponent, { ButtonSizeEnum } from '../../../../core/components/button/ButtonComponent';
import {
  useDownloadFont, useGenerateFont, usePayFont, useUpdateFont
} from './useFonts';
import { useModal } from '../../../../hooks/useModal';
import { PAYMENT_MODAL_NAME } from '../../../../constants/modalNamesConstants';

type PropsType = Pick<FontCreatorType, 'canDownloadFont' | 'canGenerateFont' | 'canPaymentFont' | 'symbols' | 'alphabetCountryCode' | 'fontId' | 'fontName'>;

const FontControlsContainer: FC<PropsType> = ({
  canGenerateFont, canPaymentFont, canDownloadFont, symbols, fontId, fontName
}: PropsType) => {
  const [searchParams] = useSearchParams();

  const isNewPayment = searchParams.get('isNewPayment');

  let buttonProps;

  const { isLoadingGenerateFont, generateFont } = useGenerateFont();
  const { isLoadingUpdateFont, updateFont } = useUpdateFont();
  const { isLoadingDownloadFont, downloadFont } = useDownloadFont();
  const { isLoadingPayFont, payFont } = usePayFont();

  const { onOpenModal } = useModal(PAYMENT_MODAL_NAME);

  const drawAlphabetsSymbols = symbols.filter(({ symbolSVGView, symbolSVGContent }) => symbolSVGView && symbolSVGContent);

  const hasGenerateFont = drawAlphabetsSymbols.length === symbols.length;

  if (canGenerateFont) {
    buttonProps = {
      text: 'Сгенерировать шрифт',
      onClick: async () => {
        if (hasGenerateFont) {
          await generateFont(fontId);
        }
      },
      isDisabled: !hasGenerateFont,
      isLoading: isLoadingGenerateFont
    };
  }

  if (canPaymentFont) {
    if (isNewPayment) {
      buttonProps = {
        text: 'Купить шрифт',
        onClick: () => onOpenModal({ fontId }),
      };
    } else {
      buttonProps = {
        text: 'Купить шрифт за 199 рублей',
        onClick: async () => {
          const { paymentConfirmationToken, status } = await payFont(fontId) || {};

          if (paymentConfirmationToken) {
            // @ts-ignore
            const checkout = new window.YooMoneyCheckoutWidget({
              confirmation_token: paymentConfirmationToken, // Токен, который перед проведением оплаты нужно получить от ЮKassa
              // return_url: 'http://localhost:3000/font-creator/', //Ссылка на страницу завершения оплаты

              // Настройка виджета
              customization: {
                // Настройка способа отображения
                modal: true
              },
              error_callback() {
                // Обработка ошибок инициализации
              }
            });

            checkout.render();

            checkout.on('success', async () => {
              await updateFont({
                fontId,
                canPaymentFont: false,
                canDownloadFont: true,
              });

              checkout.destroy();
            });

            checkout.on('fail', () => {
              // Код, который нужно выполнить после неудачной оплаты.

              // Удаление инициализированного виджета
              checkout.destroy();
            });
          }

          if (status === 'succeeded') {
            await updateFont({
              fontId,
              canPaymentFont: false,
              canDownloadFont: true,
            });
          }
        },
        isLoading: isLoadingPayFont || isLoadingUpdateFont
      };
    }
  }

  if (canDownloadFont) {
    buttonProps = {
      text: 'Скачать шрифт (TTF)',
      onClick: async () => {
        const data = await downloadFont(fontId);

        if (data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fontName}.ttf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      },
      isLoading: isLoadingDownloadFont
    };
  }

  if (!buttonProps) return null;

  return (
        <div className="">
            <ButtonComponent {...buttonProps} size={ButtonSizeEnum.SM} />
            {canGenerateFont && !hasGenerateFont && (
                <div className="text-10 leading-10 mt-4 text-primary whitespace-nowrap">
                    Сгенерировать шрифт можно только<br/>
                    после заполнения всех символов!
                </div>
            )}
        </div>
  );
};

export default FontControlsContainer;
