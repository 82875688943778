export const turkishAlphabet = {
  'U+0041': 65, // A
  'U+0061': 97, // a
  'U+0042': 66, // B
  'U+0062': 98, // b
  'U+0043': 67, // C
  'U+0063': 99, // c
  'U+00C7': 199, // Ç
  'U+00E7': 231, // ç
  'U+0044': 68, // D
  'U+0064': 100, // d
  'U+0045': 69, // E
  'U+0065': 101, // e
  'U+0046': 70, // F
  'U+0066': 102, // f
  'U+0047': 71, // G
  'U+0067': 103, // g
  'U+011E': 286, // Ğ
  'U+011F': 287, // ğ
  'U+0048': 72, // H
  'U+0068': 104, // h
  'U+0049': 73, // I
  'U+0131': 305, // ı
  'U+0130': 304, // İ
  'U+0069': 105, // i
  'U+004A': 74, // J
  'U+006A': 106, // j
  'U+004B': 75, // K
  'U+006B': 107, // k
  'U+004C': 76, // L
  'U+006C': 108, // l
  'U+004D': 77, // M
  'U+006D': 109, // m
  'U+004E': 78, // N
  'U+006E': 110, // n
  'U+004F': 79, // O
  'U+006F': 111, // o
  'U+00D6': 214, // Ö
  'U+00F6': 246, // ö
  'U+0050': 80, // P
  'U+0070': 112, // p
  'U+0052': 82, // R
  'U+0072': 114, // r
  'U+0053': 83, // S
  'U+0073': 115, // s
  'U+015E': 350, // Ş
  'U+015F': 351, // ş
  'U+0054': 84, // T
  'U+0074': 116, // t
  'U+0055': 85, // U
  'U+0075': 117, // u
  'U+00DC': 220, // Ü
  'U+00FC': 252, // ü
  'U+0056': 86, // V
  'U+0076': 118, // v
  'U+0059': 89, // Y
  'U+0079': 121, // y
  'U+005A': 90, // Z
  'U+007A': 122, // z
};
