import React, {
  FC, useEffect, useRef, useState
} from 'react';
import { fabric } from 'fabric';
import { SymbolComponent } from '../symbol/SymbolComponent';
import ButtonComponent from '../button/ButtonComponent';

type DrawType = {
  setIsNotEmpty: () => void;
  setCanvas: (canvas: fabric.Canvas | null) => void;
};

const Canvas: FC<DrawType> = ({ setCanvas, setIsNotEmpty }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const options = {
      selection: false,
      isDrawingMode: true,
    };
    const canvas = new fabric.Canvas(canvasRef.current, options);

    canvas.freeDrawingBrush.width = 5;
    canvas.svgViewportTransformation = false;

    canvas.on('path:created', () => {
      setIsNotEmpty();
    });

    setCanvas(canvas);

    return () => {
      setCanvas(null);
      canvas.dispose();
    };
  }, [canvasRef]);

  return <canvas width={150} height={200} style={{ background: 'transparent', zIndex: 1 }} ref={canvasRef} id="canvas" />;
};

type PropsType = {
  onSaveSvg: (svgViewString: string, svgViewContent: string) => void;
  symbol: string;
  svgString?: string;
  isLoadingSaveSymbol: boolean;
};

const NewDrawCardComponent: FC<PropsType> = ({
  onSaveSvg, symbol, svgString, isLoadingSaveSymbol
}: PropsType) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [isEmpty, setIsEmpty] = useState(!svgString);

  const setIsNotEmpty = () => setIsEmpty(false);

  const onExportSvg = () => {
    if (canvas) {
      const svgView = canvas.toSVG();
      let svgViewString = '';

      const getContentSvg = () => {
        const domParser = new DOMParser();
        const doc = domParser.parseFromString(svgView, 'image/svg+xml');

        const nodeSvg = doc.querySelector('svg');

        svgViewString = (nodeSvg && nodeSvg.outerHTML) || '';

        nodeSvg && document.body.appendChild(nodeSvg);

        const {
          // @ts-ignore
          x, y, width, height
        } = nodeSvg && nodeSvg.getBBox();

        nodeSvg && nodeSvg.setAttribute('viewBox', `${x - 2.5},${y - 2.5},${width + 5},${height + 5}`);
        nodeSvg && nodeSvg.setAttribute('width', `${width + 5}`);
        nodeSvg && nodeSvg.setAttribute('height', `${height + 5}`);

        const svgStringContent = nodeSvg && nodeSvg.outerHTML;

        nodeSvg && nodeSvg.remove();

        return svgStringContent;
      };

      const svgContent = getContentSvg() || '';

      onSaveSvg(svgViewString, svgContent);
    }
  };

  const onClearSvg = () => {
    if (canvas) {
      setTimeout(() => {
        canvas.clear();
      });
    }
  };

  useEffect(() => {
    onClearSvg();

    if (canvas && svgString) {
      setIsEmpty(false);

      setTimeout(() => {
        fabric.loadSVGFromString(svgString, (objects, options) => {
          const svg = fabric.util.groupSVGElements(objects, options);
          canvas.add(svg);
        });
      });
    }
  }, [svgString, symbol, canvas]);

  return (
        <div className="flex flex-col items-center">
            <SymbolComponent symbol={symbol}>
                <Canvas setCanvas={setCanvas} setIsNotEmpty={setIsNotEmpty} />
            </SymbolComponent>
            <div className="flex items-center">
                <ButtonComponent text="Очистить" onClick={onClearSvg} className="mr-8" isDisabled={isEmpty} />
                <ButtonComponent text="Сохранить" onClick={onExportSvg} isDisabled={isEmpty} isLoading={isLoadingSaveSymbol} />
            </div>
        </div>
  );
};

export default NewDrawCardComponent;
