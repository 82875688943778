export const georgianAlphabet = {
  'U+10D0': 4304, // ა
  'U+10D1': 4305, // ბ
  'U+10D2': 4306, // გ
  'U+10D3': 4307, // დ
  'U+10D4': 4308, // ე
  'U+10D5': 4309, // ვ
  'U+10D6': 4310, // ზ
  'U+10D7': 4311, // თ
  'U+10D8': 4312, // ი
  'U+10D9': 4313, // კ
  'U+10DA': 4314, // ლ
  'U+10DB': 4315, // მ
  'U+10DC': 4316, // ნ
  'U+10DD': 4317, // ო
  'U+10DE': 4318, // პ
  'U+10DF': 4319, // ჟ
  'U+10E0': 4320, // რ
  'U+10E1': 4321, // ს
  'U+10E2': 4322, // ტ
  'U+10E3': 4323, // უ
  'U+10E4': 4324, // ფ
  'U+10E5': 4325, // ქ
  'U+10E6': 4326, // ღ
  'U+10E7': 4327, // ყ
  'U+10E8': 4328, // შ
  'U+10E9': 4329, // ჩ
  'U+10EA': 4330, // ც
  'U+10EB': 4331, // ძ
  'U+10EC': 4332, // წ
  'U+10ED': 4333, // ჭ
  'U+10EE': 4334, // ხ
  'U+10EF': 4335, // ჯ
  'U+10F0': 4336, // ჰ
};
