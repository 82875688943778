import React, { FC, useState } from 'react';
import { MODAL_NAME_AUTH } from '../../constants/modalNamesConstants';
import { useModal } from '../../hooks/useModal';
import {
  AuthModalPayloadType, AuthStateType, LOGIN_STATE_TYPE, REGISTER_STATE_TYPE
} from './authTypes';
import ModalComponent from '../../core/components/modal/ModalComponent';
import { LoginContainer } from './login/LoginContainer';
import { RegisterContainer } from './register/RegisterContainer';
import TabComponent from '../../core/components/tab/TabComponent';

const modalTitles = {
  [LOGIN_STATE_TYPE]: 'Авторизация',
  [REGISTER_STATE_TYPE]: 'Регистрация',
};

const AuthModalContainer: FC = () => {
  const { onCloseModal, payload = {} } = useModal(MODAL_NAME_AUTH);

  const { selectedAuthStateType } = payload as AuthModalPayloadType;

  const [authStateType, setAuthStateType] = useState<AuthStateType>(selectedAuthStateType || LOGIN_STATE_TYPE);

  const tabs = [
    {
      id: LOGIN_STATE_TYPE,
      title: 'Авторизация',
      Content: <LoginContainer onCloseModal={onCloseModal} />,
      onChange: () => setAuthStateType(LOGIN_STATE_TYPE)
    },
    {
      id: REGISTER_STATE_TYPE,
      title: 'Регистрация',
      Content: <RegisterContainer onCloseModal={onCloseModal} />,
      onChange: () => setAuthStateType(REGISTER_STATE_TYPE)
    },
  ];

  return (
        <ModalComponent title={modalTitles[authStateType]} onClose={onCloseModal}>
            <TabComponent tabs={tabs} activeTabId={authStateType} />
        </ModalComponent>
  );
};

export default AuthModalContainer;
