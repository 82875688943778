export const specialSymbols = {
  'U+0021': 33, // !
  'U+0022': 34, // "
  'U+0023': 35, // #
  'U+0024': 36, // $
  'U+0025': 37, // %
  'U+0026': 38, // &
  'U+0027': 39, // '
  'U+0028': 40, // (
  'U+0029': 41, // )
  'U+002A': 42, // *
  'U+002B': 43, // +
  'U+002C': 44, // ,
  'U+002D': 45, // -
  'U+002E': 46, // .
  'U+002F': 47, // /
  'U+003A': 58, // :
  'U+003B': 59, // ;
  'U+003C': 60, // <
  'U+003D': 61, // =
  'U+003E': 62, // >
  'U+003F': 63, // ?
  'U+0040': 64, // @
  'U+005B': 91, // [
  'U+005C': 92, // \
  'U+005D': 93, // ]
  'U+005E': 94, // ^
  'U+005F': 95, // _
  'U+0060': 96, // `
  'U+007B': 123, // {
  'U+007C': 124, // |
  'U+007D': 125, // }
  'U+007E': 126, // ~
};
