export type ImageSettingsTypes = {
  imageType: ImageTypeEnum
};

export enum ImageTypeEnum {
  CAGE = 'cage',
  LINE = 'line',
  EMPTY = 'empty',
  CUSTOM = 'custom',
}
