import { useDispatch } from 'react-redux';
import { statsUrl } from '@pisaka/core/endpointUrls/endpointUrls';
import { axiosInstance } from '../../helpers/axiosInstance';
import { StatsType } from './statsTypes';
import { setStatsAction } from './state/actions';

export const useStats = () => {
  const dispatch = useDispatch();

  const loadStatsData = async () => {
    const { data: { fontsCounter, usersCounter } } = await axiosInstance().post<StatsType>(statsUrl);

    dispatch(setStatsAction({ fontsCounter, usersCounter }));
  };

  return {
    loadStatsData
  };
};
