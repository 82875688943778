import React, { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

export enum ModalSizeEnum {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

type PropsType = {
  title?: string;
  children?: ReactNode;
  onClose?: () => void;
  size?: ModalSizeEnum;
};

const ModalComponent: FC<PropsType> = ({
  title, children, onClose, size = ModalSizeEnum.LG
}: PropsType) => createPortal(
    <>
        <div className="flex items-center justify-center fixed left-0 top-0 right-0 bottom-0 z-20 m-auto">
            <div className={classNames('bg-white rounded-3', {
              'w-200': size === ModalSizeEnum.SM,
              'w-400': size === ModalSizeEnum.MD,
              'w-600': size === ModalSizeEnum.LG,
            })}>
                <div className="flex items-center justify-between p-16 border-b border-solid border-gray">
                    <div className="text-20">{title}</div>
                    {onClose && (
                        <div
                            className="flex items-center justify-center h-32 w-32 border border-solid border-gray cursor-pointer"
                            onClick={onClose}
                        >
                            𐄂
                        </div>
                    )}
                </div>
                <div className="p-16">{children}</div>
            </div>
        </div>
        <div className="fixed left-0 top-0 right-0 bottom-0 bg-black opacity-50 z-10" />
    </>,
    document.body
);

export default ModalComponent;
