import React, { FC, ChangeEvent, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './inputComponent.module.css';

type PropsType = {
  value?: string;
  errorText?: string;
  onChange: (value: string) => void;
  placeholder?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

const InputComponent: FC<PropsType> = ({
  value, errorText, onChange, placeholder, ...inputProps
}: PropsType) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value);

  return (
        <div>
            <input
                className={classNames(styles.input, { '!border-danger': errorText })}
                value={value}
                onChange={onInputChange}
                placeholder={placeholder}
                {...inputProps}
            />
            {!!errorText && <div className="text-danger text-12 mt-4">{errorText}</div>}
        </div>
  );
};

export default InputComponent;
