import React, {
  FunctionComponent, MouseEvent, useEffect, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderComponent from '../core/components/header/HeaderComponent';
import { AppStateTypes } from '../store/appStateTypes';
import ButtonComponent, { ButtonSizeEnum } from '../core/components/button/ButtonComponent';
import { logoutTypeAction } from '../modules/auth/state/actions';
import { removeCookie } from '../helpers/cookie';
import { COOKIE_NAME_AUTH_TOKEN } from '../constants/cookieNamesConstants';
import { SidebarComponent } from '../core/components/sidebar/SidebarComponent';
import { useModal } from '../hooks/useModal';
import { MODAL_NAME_AUTH } from '../constants/modalNamesConstants';
import { REGISTER_STATE_TYPE } from '../modules/auth/authTypes';
import AuthModalContainer from '../modules/auth/AuthModalContainer';
import { StatsContainer } from '../modules/stats/StatsContainer';
import { useStatsSocket } from '../hooks/useStatsSocket';

export const withHeader = (Component: FunctionComponent) => () => {
  useStatsSocket();

  const dispatch = useDispatch();

  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  const toggleSidebar = () => setIsOpenSidebar((prevState) => !prevState);

  const { user } = useSelector((state: AppStateTypes) => state.auth) || {};

  const logout = () => {
    dispatch(logoutTypeAction());
    removeCookie(COOKIE_NAME_AUTH_TOKEN);
  };

  const { isOpenModal: isOpenAuthModal, onOpenModal } = useModal(MODAL_NAME_AUTH);

  const onOpenRegisterModal = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    onOpenModal({ selectedAuthStateType: REGISTER_STATE_TYPE });
  };

  const onOpenAuthModal = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    onOpenModal();
  };

  const refAd = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refAd) {
      // @ts-ignore
      window.yaContextCb.push(() => {
        // @ts-ignore
        Ya.Context.AdvManager.render({
          blockId: 'R-A-3642064-6',
          type: 'floorAd',
          platform: 'touch'
        });
      });
    }
  }, [refAd]);

  return (
        <>
          <HeaderComponent
              toggleSidebar={toggleSidebar}
          />
          <Component />
          {isOpenSidebar && (
                <SidebarComponent
                    toggleSidebar={toggleSidebar}
                    StatsContent={<StatsContainer />}
                    AuthContent={user ? (
                        <>
                            <div className="truncate">{user.email}</div>
                            <ButtonComponent text="Выйти" onClick={logout} className="ml-8" size={ButtonSizeEnum.SM} />
                        </>
                    ) : (
                        <>
                            <a href="#" onClick={onOpenRegisterModal} className="text-primary mr-8">Регистрация</a>
                            <a href="#" onClick={onOpenAuthModal} className="text-primary">Авторизация</a>
                        </>
                    )}
                />
          )}
            <div className='mb-15' ref={refAd} id="yandex_rtb_R-A-3642064-6" />
          {isOpenAuthModal && <AuthModalContainer />}
        </>
  );
};
