import React, { FC } from 'react';
import InputComponent from '../../../core/components/input/InputComponent';
import { PanelSubSectionComponent } from '../../../core/components/panel/PanelComponent';
import ButtonComponent from '../../../core/components/button/ButtonComponent';
import { useLogin, useAuth } from '../useAuth';

type LoginContainerType = {
  onCloseModal: () => void;
};

export const LoginContainer: FC<LoginContainerType> = ({ onCloseModal }) => {
  const {
    isLoadingLogin, login, loginError, setLoginError
  } = useLogin();

  const {
    email,
    emailError,
    isValidEmail,
    password,
    passwordError,
    isValidPassword,
    onChangeEmail,
    onChangePassword,
  } = useAuth();

  const onLogin = async () => {
    try {
      await login(email, password);
      onCloseModal();

      setLoginError(undefined);
    } catch (error) {
      if (error instanceof Error) {
        setLoginError(error.message);
      }
    }
  };

  return (
        <>
            <PanelSubSectionComponent>
                <p className="mb-4">Введите email:</p>
                <InputComponent onChange={onChangeEmail} value={email} placeholder="Email" errorText={emailError} />
            </PanelSubSectionComponent>
            <PanelSubSectionComponent>
                <p className="mb-4">Введите пароль:</p>
                <InputComponent onChange={onChangePassword} value={password} placeholder="Пароль" type="password" errorText={passwordError} />
            </PanelSubSectionComponent>
            {loginError && <div className="text-danger text-14">{loginError}</div>}
            <ButtonComponent
                className="mt-16"
                text="Войти"
                onClick={onLogin}
                isLoading={isLoadingLogin}
                isDisabled={isLoadingLogin || !isValidEmail || !isValidPassword}
            />
        </>
  );
};
